// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { curveMonotoneX } from '@visx/curve';
import { Area, Circle, LinePath } from '@visx/shape';

import { AGPCurvesProps } from '@/components/data-representations/AgpProfile/types';
import { useStyles } from '@/hooks/useStyles';

export default function AGPCurves({ agp, scales }: AGPCurvesProps) {
  const { rescaleX, rescaleY } = scales;
  const styles = useStyles(makeStyles);

  if (Object.keys(agp).length === 1) {
    const key = Object.keys(agp)[0];
    const values = Object.values(agp)[0];
    return (
      <Circle
        cx={rescaleX(parseInt(key))}
        r={5}
        cy={rescaleY(values['50'])}
        className={styles.circle}
      />
    );
  }
  return (
    <>
      <LinePath
        strokeLinejoin="round"
        strokeLinecap="round"
        curve={curveMonotoneX}
        data={Object.entries(agp)}
        x={([hour]) => rescaleX(parseInt(hour))}
        y={([, values]) => rescaleY(values['50'])}
        className={styles.median}
      />

      <Area
        curve={curveMonotoneX}
        data={Object.entries(agp)}
        x={([hour]) => rescaleX(parseInt(hour))}
        y0={([, values]) => rescaleY(values['5'])}
        y1={([, values]) => rescaleY(values['95'])}
        className={styles.p90}
      />

      <Area
        curve={curveMonotoneX}
        data={Object.entries(agp)}
        x={([hour]) => rescaleX(parseInt(hour))}
        y0={([, values]) => rescaleY(values['25'])}
        y1={([, values]) => rescaleY(values['75'])}
        className={styles.p50}
      />
    </>
  );
}

const makeStyles = (theme: Theme) => ({
  circle: css`
    color: ${theme.palette.agp.median};
    fill: currentColor;
  `,
  p90: css`
    color: ${theme.palette.agp.p90};
    fill: currentColor;
  `,
  p50: css`
    color: ${theme.palette.agp.p50};
    fill: currentColor;
  `,
  median: css`
    color: ${theme.palette.agp.median};
    stroke: currentColor;
    stroke-width: 2;
  `,
});
