import { DateTime } from 'luxon';

import { CarePlan, TelemonitoringTier } from './CarePlanModel';
import { ConditionType, MedicationSchema } from './ConditionModel';
import { TeleMonitoringCriteria } from './TelemonitoringCriteriaModel';

export type CarePlanCreationDirectives = {
  periodStart: DateTime;
};

type TelemonitoringCriteriaMapMedication = {
  [key in MedicationSchema]: TeleMonitoringCriteria[];
};

type TelemonitoringCriteriaMap = {
  [key in ConditionType]: TelemonitoringCriteriaMapMedication;
};

type PreviousCarePlanData = Partial<Omit<CarePlan, 'prescription'>>; // The prescription cannot be reused in the new careplan
export type TeleMonitoringCriteriaMap = {
  [key in TeleMonitoringCriteria]: TelemonitoringTier; // Gives the tier for each criteria
};

export type CarePlanDirectives = {
  previousCarePlanData: PreviousCarePlanData; // Used to pre-fill the renewal form
  carePlanCreationDataDirectives: CarePlanCreationDirectives; // Used to pass data computed by the backend needed to be known by the practitioner (periodStart for example)
  telemonitoringCriteriaMap: TelemonitoringCriteriaMap; // Gives the criteras available for each condition and medication
  telemonitoringTierMap: TeleMonitoringCriteriaMap; // For each criteria, gives the tier
};

export const carePlanDirectivesDecoder = (
  value: unknown,
): CarePlanDirectives => {
  if ((value as any).carePlanCreationDataDirectives.periodStart as string) {
    (value as any).carePlanCreationDataDirectives.periodStart =
      DateTime.fromISO(
        (value as any).carePlanCreationDataDirectives.periodStart as string,
      );
  }
  return value as CarePlanDirectives;
}; // TODO FIXME create a real decoder
