import { useTranslation } from 'react-i18next';

import { CardTitle } from '@/components/card/CardTitle';
import { Typo } from '@/components/typography/Text';

export const BGMContainerHeader = () => {
  const { t } = useTranslation();

  return (
    <CardTitle>
      <Typo type="title">{t('bgmLogBook.cardTitle')}</Typo>
    </CardTitle>
  );
};
