import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';

type SelectedPeriodProps = {
  from: string;
  to: string;
};

export const SelectedPeriod: React.FC<SelectedPeriodProps> = ({ from, to }) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  const upperDate: Date = new Date(to);
  const displayedUpperDate: Date = new Date(
    upperDate.setDate(upperDate.getDate() - 1),
  );
  const downerDate: Date = new Date(from);

  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));
  const yesterdayWithoutHour = yesterday.setHours(0, 0, 0, 0);

  const yesterdayLabel = t('periodicity.yesterday');
  const downerTitle =
    downerDate.setHours(0, 0, 0, 0) == yesterdayWithoutHour
      ? yesterdayLabel
      : downerDate.toLocaleDateString();
  const upperTitle =
    displayedUpperDate.setHours(0, 0, 0, 0) == yesterdayWithoutHour
      ? yesterdayLabel
      : displayedUpperDate.toLocaleDateString();
  const inputTitle = downerTitle + ' - ' + upperTitle;

  return <div className={styles.input}>{inputTitle}</div>;
};

const makeStyles = (theme: Theme) => ({
  input: css`
    height: fit-content;
    padding: ${theme.spacing(4)} ${theme.spacing(8)};
    text-align: center;
    background-color: ${theme.palette.common.white};
    width: 30vh;
  `,
});
