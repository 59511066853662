import { useState } from 'react';

import { Typography } from '@mui/material';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { TelemonitoringTags } from '@/models/CarePlanModel';
import { PatientList } from '@/models/PatientModel';

import { PatientTableRenewButton } from '../../PatientTableRenewButton';
import { PatientsTagsList } from '../../PatientsTagsList';
import { PatientForDoctor } from '../PatientDoctorTable';

const columnHelper = createColumnHelper<PatientForDoctor | PatientList>();

export const telemonitoringColumnDoctor: ColumnDef<
  PatientForDoctor | PatientList,
  TelemonitoringTags[]
> = columnHelper.accessor('telemonitoring_tags', {
  // @ts-expect-error: Type mismatch due to complex generics handling
  cell: ({ row, isHovered, getValue }) => {
    const { id, telemonitoring_tags, renewal_suggestion } = row.original;
    const tags = <PatientsTagsList telemonitoringTags={telemonitoring_tags} />;
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <PatientTableRenewButton
        renewalSuggestion={renewal_suggestion}
        patientId={id}
        isButtonVisible={isHovered && renewal_suggestion}
        isModalOpen={isModalOpen}
        openModal={setIsModalOpen}
      >
        {tags}
      </PatientTableRenewButton>
    );
  },
  header: () => {
    const { t } = useTranslation();
    return <Typography>{t('pages.patients.notifications')}</Typography>;
  },
  size: 18,
  maxSize: 18,
});
