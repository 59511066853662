import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { MenuTooltip } from '@/components/floating/MenuTooltip';
import { Col, Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useStyles';
import { Patient } from '@/models/PatientModel';
import { inseeTownshipToString } from '@/models/identity/FrenchIdentity';
import { Queries } from '@/queries/Queries';
import { getAge } from '@/utils/date';

type PatientProps = {
  patientInformation: Patient;
};

type PatientProfileProps = {
  patientId: string;
};

export const PatientProfile: React.FC<PatientProfileProps> = ({
  patientId,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const patientInformationData = Queries.practitioner.usePatient(patientId);

  if (!patientInformationData.data) {
    return null;
  }

  const patientInformation = patientInformationData.data;

  return (
    <Card elevation={0}>
      <TextCardTitle title={t('pages.account.generalInformation.title')} />
      <CardBody className={styles.body}>
        <Col className={styles.content}>
          <Row className={styles.multiCol}>
            <Col className={styles.column}>
              <NameInfo patientInformation={patientInformation} />
              <SexInfo patientInformation={patientInformation} />
              <BirthInfo patientInformation={patientInformation} />
            </Col>
            <Col className={styles.column}>
              <PhoneInfo patientInformation={patientInformation} />
              <EmailInfo patientInformation={patientInformation} />
              <AddressInfo patientInformation={patientInformation} />
            </Col>
          </Row>
          <MenuTooltip
            placement="bottom-start"
            openOnHover
            className={styles.moreInfo}
            content={
              <Row className={cx(styles.multiCol, styles.tooltip)}>
                <Col className={styles.column}>
                  <Typo type="paragraph">{`${t('profile.usedFirstname')} : ${
                    patientInformation.givenName ?? t('profile.notGiven')
                  }`}</Typo>
                  <Typo type="paragraph">{`${t('profile.usedLastname')} : ${
                    patientInformation.familyName ?? t('profile.notGiven')
                  }`}</Typo>
                  <Typo type="paragraph">{`${t(
                    'profile.identityFirstnames',
                  )} : ${
                    patientInformation.identityFirstnames.length > 0
                      ? patientInformation.identityFirstnames.join(', ')
                      : t('profile.notGiven')
                  }`}</Typo>
                  <Typo type="paragraph">{`${t('profile.identityLastname')} : ${
                    patientInformation.identityLastname ?? t('profile.notGiven')
                  }`}</Typo>
                  <Typo type="paragraph">{`${t('profile.gender')} : ${
                    patientInformation.gender == 'male'
                      ? t('profile.male')
                      : patientInformation.gender == 'female'
                        ? t('profile.female')
                        : t('profile.notGiven')
                  }`}</Typo>
                </Col>
                <Col className={styles.column}>
                  <BirthDateDetailsInfo
                    patientInformation={patientInformation}
                  />
                  <BirthLocationDetailsInfo
                    patientInformation={patientInformation}
                  />
                  <Typo type="paragraph">{`${t('profile.ins')} : ${
                    patientInformation.ins
                      ? patientInformation.ins
                      : t('profile.notGiven')
                  }`}</Typo>
                  <Typo type="paragraph">{`${t('profile.oid')} : ${
                    patientInformation.oid
                      ? patientInformation.oid
                      : t('profile.notGiven')
                  }`}</Typo>
                  <Typo type="paragraph">{`${t('profile.ssn')} : ${
                    patientInformation.socialSecurityNumber ??
                    t('profile.notGiven')
                  }`}</Typo>
                </Col>
              </Row>
            }
          >
            <Row className={styles.itemRow}>
              <Typo type="paragraph">{t('profile.moreInfo')}</Typo>
              <Icons.chevronDown className={styles.icon} />
            </Row>
          </MenuTooltip>
        </Col>
      </CardBody>
    </Card>
  );
};

const NameInfo: React.FC<PatientProps> = ({ patientInformation }) => {
  return (
    <Row>
      <Typo type="paragraph">{`${patientInformation.givenName} ${patientInformation.familyName}`}</Typo>
    </Row>
  );
};

const SexInfo: React.FC<PatientProps> = ({ patientInformation }) => {
  const { t } = useTranslation();

  if (!patientInformation.gender) {
    return null;
  }
  return (
    <Typo type="paragraph">
      {patientInformation.gender == 'male'
        ? t('profile.male')
        : t('profile.female')}
    </Typo>
  );
};

const BirthInfo: React.FC<PatientProps> = ({ patientInformation }) => {
  const { t } = useTranslation();

  if (!patientInformation.birthDate || !patientInformation.birthCountry) {
    return null;
  }
  const age = getAge(patientInformation.birthDate);
  const birthLocation = patientInformation.birthLocation
    ? `${t('patientInfo.placeOfBirth', {
        context: patientInformation.gender,
      })} ${inseeTownshipToString(patientInformation.birthLocation)}, ${
        patientInformation.birthCountry
      }`
    : `${t('patientInfo.placeOfBirth', {
        context: patientInformation.gender,
      })} ${patientInformation.birthCountry}`;

  return (
    <Typo type="paragraph">
      {`${t('pages.patientMonitoring.patient.age', {
        age,
      })}, ${birthLocation}`}
    </Typo>
  );
};

const PhoneInfo: React.FC<PatientProps> = ({ patientInformation }) => {
  const styles = useStyles(makeStyles);

  if (!patientInformation.contactPhone) {
    return null;
  }
  return (
    <Row className={styles.itemRow}>
      <Icons.phone className={styles.icon} />
      <Typo type="paragraph">{patientInformation.contactPhone}</Typo>
    </Row>
  );
};

const EmailInfo: React.FC<PatientProps> = ({ patientInformation }) => {
  const styles = useStyles(makeStyles);

  return (
    <Row className={styles.itemRow}>
      <Icons.email className={styles.icon} />
      <Typo type="paragraph">{patientInformation.email}</Typo>
    </Row>
  );
};

const AddressInfo: React.FC<PatientProps> = ({ patientInformation }) => {
  const styles = useStyles(makeStyles);
  if (
    !patientInformation.addressLine ||
    !patientInformation.addressPostalCode ||
    !patientInformation.addressCity
  ) {
    return null;
  }

  return (
    <Row className={styles.itemRow}>
      <Icons.mail className={styles.icon} />
      <Typo type="paragraph">
        {`${patientInformation.addressLine}, ${patientInformation.addressPostalCode} ${patientInformation.addressCity}`}
      </Typo>
    </Row>
  );
};

const BirthDateDetailsInfo: React.FC<PatientProps> = ({
  patientInformation,
}) => {
  const { t } = useTranslation();
  if (!patientInformation.birthDate) {
    return (
      <Typo type="paragraph">{`${t('profile.birthDate')} : ${t(
        'profile.notGiven',
      )}`}</Typo>
    );
  }
  const age = getAge(patientInformation.birthDate);
  const birthDate = patientInformation.birthDate.toLocaleString();
  const ageText = `(${t('pages.patientMonitoring.patient.age', {
    age,
  })})`;

  return (
    <Typo type="paragraph">{`${t(
      'profile.birthDate',
    )} : ${birthDate} ${ageText}`}</Typo>
  );
};

const BirthLocationDetailsInfo: React.FC<PatientProps> = ({
  patientInformation,
}) => {
  const { t } = useTranslation();
  if (!patientInformation.birthCountry) {
    return (
      <Typo type="paragraph">{`${t('profile.birthPlace')} : ${t(
        'profile.notGiven',
      )}`}</Typo>
    );
  }
  const birthLocation = patientInformation.birthLocation
    ? `${t('patientInfo.placeOfBirth', {
        context: patientInformation.gender,
      })} ${inseeTownshipToString(patientInformation.birthLocation)}, ${
        patientInformation.birthCountry
      }`
    : `${t('patientInfo.countryOfBirth', {
        context: patientInformation.gender,
      })} ${patientInformation.birthCountry}`;

  return (
    <Typo type="paragraph">{`${t(
      'profile.birthPlace',
    )} : ${birthLocation}`}</Typo>
  );
};

const makeStyles = (theme: Theme) => ({
  body: css``,
  content: css`
    width: 100%;
  `,
  multiCol: css`
    gap: ${theme.spacing(30)};
  `,
  column: css`
    gap: ${theme.spacing(4)};
  `,
  itemRow: css`
    align-items: center;
  `,
  moreInfo: css`
    margin-top: ${theme.spacing(12)};
    width: fit-content;
  `,
  tooltip: css`
    margin: ${theme.spacing(12)};
  `,
  icon: css`
    width: ${FontSizes.body};
    height: ${FontSizes.body};
    margin: 0 ${theme.spacing(4)};
  `,
});
