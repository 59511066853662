import {
  DecoderFunction,
  array,
  record,
  string,
} from 'typescript-json-decoder';

import { nullOrUndef } from '@/utils/decoderUtils';

export type BirthLocation = {
  town: string;
  post_code: string;
};

export type IdentityValidation = {
  patientId: string;
  givenName: string;
  familyName: string;

  regulatoryCountry: string;
  identityLastname: string;
  identityFirstnames: string[];
  gender: string;
  franceBirthLocation?: BirthLocation;
  franceBirthCountry?: string;
  birthDate: string;
};

const birthLocationDecoder: DecoderFunction<BirthLocation> = record({
  town: string,
  post_code: string,
});

export const identityValidationDecoder: DecoderFunction<IdentityValidation> =
  record({
    givenName: string,
    familyName: string,
    regulatoryCountry: string,
    identityLastname: string,
    identityFirstnames: array(string),
    gender: string,
    franceBirthLocation: nullOrUndef(birthLocationDecoder),
    franceBirthCountry: string,
    birthDate: string,
    patientId: string,
  });
