import { css } from '@emotion/css';
import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { Table, flexRender } from '@tanstack/react-table';

import { useStyles } from '@/hooks/useStyles';
import { BGMData } from '@/models/BGMLogbook';

export const BGMTableHeader = ({ table }: { table: Table<BGMData> }) => {
  const styles = useStyles(makeStyles);
  return (
    <TableHead className={styles.header}>
      {table.getHeaderGroups().map(headerGroup => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map(header => (
            <TableCell
              size="small"
              padding="none"
              key={header.id}
              colSpan={header.colSpan}
              className={styles.headerSection}
              sx={{
                borderBottomColor: 'white',
                width: header.getSize(),
              }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
};

const makeStyles = (theme: Theme) => ({
  header: css({
    position: 'sticky',
    top: 0,
    '& .MuiTableCell-root': {
      backgroundColor: theme.palette.bgm.header,
      border: '1px solid white',
      textAlign: 'center',
    },
  }),
  headerSection: css`
    border-color: white;
  `,
});
