import { DecoderFunction, string } from 'typescript-json-decoder';

import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

type ConditionName = 'diabetes';
export type ConditionType = 'type1' | 'type2' | 'gestational';
export type MedicationSchema = 'oral' | 'mono' | 'multi';

export type Condition = {
  periodStart: string;
  periodEnd?: string;
  name: ConditionName;
  type: ConditionType;
  medicationSchema: MedicationSchema;
};

export const conditionerDecoder: DecoderFunction<Condition> = recordWithContext(
  'Condition',
  {
    periodStart: string,
    periodEnd: nullOrUndef(string),
    name: stringUnion('diabetes'),
    type: stringUnion('type1', 'type2', 'gestational'),
    medicationSchema: stringUnion('oral', 'mono', 'multi'),
  },
);
