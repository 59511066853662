import { Reducer } from 'react';

import { CarePlanData, PatientInfoData } from '@/models/CreatePatientModel';

export type AddPatientFormData =
  | {
      step: 'PatientInfo';
      patientInfo?: PatientInfoData;
      carePlan?: Partial<CarePlanData>;
    }
  | {
      step: 'CarePlan';
      patientInfo: PatientInfoData;
      carePlan?: Partial<CarePlanData>;
    }
  | {
      step: 'Prescription';
      patientInfo: PatientInfoData;
      carePlan: CarePlanData;
    };

export type AddPatientAction =
  | {
      type: 'BACK_TO_PATIENT_INFO';
      carePlan: Partial<CarePlanData>;
      patientInfo: PatientInfoData;
    }
  | {
      type: 'TO_CAREPLAN';
      patientInfo: PatientInfoData;
      carePlan?: Partial<CarePlanData>;
    }
  | {
      type: 'TO_PRESCRIPTION';
      carePlan: CarePlanData;
      patientInfo: PatientInfoData;
    }
  | {
      type: 'BACK_TO_CAREPLAN';
      patientInfo: PatientInfoData;
      carePlan: CarePlanData;
    };

export const addPatientReducer: Reducer<
  AddPatientFormData,
  AddPatientAction
> = (prevState, action) => {
  switch (action.type) {
    case 'BACK_TO_PATIENT_INFO':
      return {
        ...prevState,
        step: 'PatientInfo',
        carePlan: {
          ...action.carePlan,
        },
      };
    case 'TO_CAREPLAN':
      return {
        ...prevState,
        step: 'CarePlan',
        patientInfo: action.patientInfo,
        carePlan: action.carePlan,
      };
    case 'TO_PRESCRIPTION':
      return {
        ...prevState,
        step: 'Prescription',
        patientInfo: action.patientInfo,
        carePlan: action.carePlan,
      };
    case 'BACK_TO_CAREPLAN':
      return {
        ...prevState,
        step: 'CarePlan',
        patientInfo: action.patientInfo,
      };
  }
};
