import moment from 'moment';

export const capitalizeChar = (str: string) =>
  str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();

export function range(
  start: number,
  stop: number | undefined = undefined,
  step = 1,
  circularFill = false,
  map = (value: number) => value,
) {
  if (typeof stop === 'undefined') {
    stop = start;
    start = 0;
  }

  if (step > 0 && start >= stop) {
    step = -step;
  }

  if (step < 0 && start <= stop) {
    return [];
  }

  let index = start;
  const result = [];

  if (circularFill) {
    const size = start + stop;
    for (index; step > 0 ? index < size : index > size; index += step) {
      result.push(map(index % stop));
    }
    return result;
  }

  for (index; step > 0 ? index < stop : index > stop; index += step) {
    result.push(map(index));
  }

  return result;
}

type AnyValidDate = string | Date | moment.Moment;

export const isSameDate = (date?: AnyValidDate, refDate?: AnyValidDate) =>
  (date && refDate && moment(date).isSame(moment(refDate), 'day')) || false;

/* Kinda like Python's defaultdict, but for JS*/
/*  default value from fonction*/
// Ex: dict = defDict(()=>[])
//  dict.get("key") -> return empty list
//  dict.get("key").push(3) -> works even if the key is currently unknown in the dict.
function defDict<T>(type: () => T) {
  const dict = {} as Record<string, T>;
  return {
    get: function (key: number) {
      if (!dict[key]) {
        dict[key] = type();
      }
      return dict[key];
    },
    dict: dict,
  };
}

//from two array (keys, values) to dict
function toObj<KeysType extends string | number | symbol, ValuesType>(
  ks: KeysType[],
  vs: ValuesType[],
) {
  return ks.reduce(
    (o, k, i) => {
      o[k] = vs[i];
      return o;
    },
    {} as Record<KeysType, ValuesType>,
  );
}

// findOverlap from data
// data : [{position, size}]
// return :  indexes of overlapping groups of elements : [[0,1,4], [2,3]]
type OverlappingType = {
  position: number;
  size: number;
};

export function findOverlap(data: OverlappingType[]) {
  const sortedRangesIndex = range(data.length).sort(
    (previous, current) => data[previous].position - data[current].position,
  );
  const mappingSortedIndexToDataIndex = toObj(
    range(sortedRangesIndex.length),
    sortedRangesIndex,
  );

  const sortedRanges = sortedRangesIndex.map(sortedInd => data[sortedInd]);

  const mappingGroup = {} as Record<number, number>;
  const groups = defDict<number[]>(() => []);

  sortedRanges.forEach((current, idx) => {
    // get the previous range
    if (idx === 0) {
      return;
    }
    const previous = sortedRanges[idx - 1];

    // check for any overlap
    const previousEnd = previous.position + previous.size;
    const currentStart = current.position;
    const overlap = previousEnd >= currentStart;

    // store the result
    if (overlap) {
      let groupIndex = Object.keys(groups.dict).length;
      if (idx - 1 in mappingGroup) {
        groupIndex = mappingGroup[idx - 1];
      } else {
        groups.get(groupIndex).push(mappingSortedIndexToDataIndex[idx - 1]);
        mappingGroup[idx - 1] = groupIndex;
      }
      groups.get(groupIndex).push(mappingSortedIndexToDataIndex[idx]);
      mappingGroup[idx] = groupIndex;
    }
  });

  // return the final results
  return Object.values(groups.dict);
}

export function isNotNull<ArgType>(arg: ArgType | null): arg is ArgType {
  return arg !== null;
}
