import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import moment from 'moment';
import ReactCalendar, {
  CalendarProps as ReactCalendarProps,
  TileArgs,
} from 'react-calendar';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import { isSameDate } from '@/utils/common';

type CalendarProps = ReactCalendarProps & {
  date: Date;
  onDateChange: (date: Date) => void;
  refDate?: Date;
  className?: string;
};

export function Calendar({
  date,
  onDateChange,
  refDate,
  className = '',
  ...rest
}: CalendarProps) {
  const { current: today } = React.useRef(moment().startOf('d').toDate());
  const styles = useStyles(makeStyles);
  const tileStyle = useStyles(makeTileStyles);
  const formatWeekday = (_: unknown, date: Date) =>
    moment(date).format('dd').charAt(0).capitalize();

  return (
    <ReactCalendar
      className={cx(styles.calendar, className)}
      value={date}
      onClickDay={value => onDateChange(value)}
      navigationLabel={({ label }) => <NavigationLabel label={label} />}
      prevLabel={<Icons.chevronLeft className="calendar-custom-navigator" />}
      nextLabel={<Icons.chevronRight className="calendar-custom-navigator" />}
      prev2Label={null}
      next2Label={null}
      formatShortWeekday={formatWeekday}
      tileClassName={tile =>
        `calendar-custom-tile ${tileStyle({
          tile,
          date,
          today,
          refDate,
          disabledPredicate: rest.tileDisabled,
        })}`
      }
      defaultView="month"
      {...rest}
    />
  );
}

type TileStylesArgs = {
  tile: TileArgs;
  date: Date;
  today: Date;
  refDate?: Date;
  disabledPredicate?: (props: TileArgs) => boolean;
};

const NavigationLabel = ({ label }: { label: string }) => (
  <Typography
    variant="body"
    fontWeight="bold"
    className={css`
      text-align: center;
    `}
  >
    {label.capitalize()}
  </Typography>
);

const makeTileStyles =
  (theme: Theme) =>
  ({ tile, date, today, refDate, disabledPredicate }: TileStylesArgs) => {
    if (isSameDate(tile.date, refDate)) {
      return css`
        color: ${theme.palette.text.primary};
        background-color: ${theme.palette.primary.contrastText};
      `;
    }
    if (isSameDate(tile.date, date)) {
      return css`
        color: ${theme.palette.common.white};
        background-color: ${theme.palette.primary.light};
      `;
    }
    if (disabledPredicate) {
      if (disabledPredicate(tile)) {
        return css`
          opacity: 0.5;
        `;
      }
    } else if (tile.date < today) {
      return css`
        opacity: 0.5;
      `;
    }
    return css``;
  };

const makeStyles = () => ({
  calendar: css`
    .react-calendar__navigation__arrow {
      align-items: center;
      text-align: center;
    }
    .react-calendar__navigation__label {
      align-items: center;
      text-align: center;
    }
  `,
});
