import { useReducer } from 'react';

import { Dialog } from '@mui/material';

import { CarePlanCreationDirectives } from '@/models/CarePlanDirectivesModel';
import { TelemonitoringTier } from '@/models/CarePlanModel';
import { PrescriptionPatientInfoData } from '@/models/CreatePatientModel';
import { PrescriptionGenerationHandler } from '@/models/PrescriptionGenerationModel';
import { Queries } from '@/queries/Queries';

import { GenerateOrUploadPrescription } from './GenerateOrUploadPrescription';
import {
  AddPrescriptionActions,
  AddPrescriptionData,
  AddPrescriptionReducer,
  PartialPrescriptionGenerationData,
} from './GeneratePrescriptionReducer';
import { PrescriptionInformation } from './PrescriptionInformation';
import { PrescriptionSignature } from './PrescriptionSignature';

type ContainerProps = {
  dispatch: React.Dispatch<AddPrescriptionActions>;
  addPrescriptionData: AddPrescriptionData;
  onPrescriptionReady: PrescriptionGenerationHandler;
  onCloseModal: () => void;
  patientId?: string;
};
const Container = ({
  dispatch,
  addPrescriptionData,
  onPrescriptionReady,
  onCloseModal,
  patientId,
}: ContainerProps) => {
  const generatePrescriptionMutation =
    Queries.prescription.useGeneratePrescription();

  switch (addPrescriptionData.step) {
    case 'GenerateOrUploadPrescription':
      return (
        <GenerateOrUploadPrescription
          onGeneratePrescription={() => {
            dispatch({
              type: 'TO_GET_PRESCRIPTION_DATA',
              prescriptionGenerationData:
                addPrescriptionData.prescriptionGenerationData,
            });
          }}
          patientInfo={{
            givenName:
              addPrescriptionData.prescriptionGenerationData.first_name,
            familyName:
              addPrescriptionData.prescriptionGenerationData.last_name,
          }}
          onPrescriptionReady={onPrescriptionReady}
          onBack={onCloseModal}
          patientId={patientId}
        />
      );
    case 'GetPrescriptionData':
      return (
        <PrescriptionInformation
          onSubmit={formData =>
            generatePrescriptionMutation.mutate(
              {
                ...addPrescriptionData.prescriptionGenerationData,
                ...formData,
                periodStart:
                  addPrescriptionData.prescriptionGenerationData.periodStart.toISODate() as string,
                birthDate: formData.birthDate.toISODate() as string,
              },
              {
                onSuccess: prescriptionData => {
                  dispatch({
                    type: 'TO_PRESCRIPTION_SIGNATURE',
                    prescriptionGenerationData: {
                      ...addPrescriptionData.prescriptionGenerationData,
                      ...formData,
                    },
                    prescription: prescriptionData,
                  });
                },
              },
            )
          }
          prescriptionInfo={addPrescriptionData.prescriptionGenerationData}
          onBack={data =>
            dispatch({
              type: 'BACK_TO_GENERATE_OR_UPLOAD',
              prescriptionGenerationData: data,
            })
          }
        />
      );
    case 'PrescriptionSignature':
      return (
        <PrescriptionSignature
          onPrescriptionSigned={onPrescriptionReady}
          prescription={addPrescriptionData.prescription}
          prescriptionData={addPrescriptionData.prescriptionGenerationData}
          onBack={() =>
            dispatch({
              type: 'BACK_TO_PRESCRIPTION_DATA',
              prescriptionGenerationData:
                addPrescriptionData.prescriptionGenerationData,
            })
          }
        />
      );
    default:
      return null;
  }
};

type PrescriptionModalProps = {
  patientId?: string;
  patientInfo: PrescriptionPatientInfoData;
  duration: string;
  onPrescriptionReady: PrescriptionGenerationHandler;
  telemonitoringTier: TelemonitoringTier;
  carePlanCreationDataDirectives: CarePlanCreationDirectives;
  onClose: () => void;
};
export const PrescriptionModal = ({
  patientInfo,
  duration,
  onPrescriptionReady,
  onClose,
  telemonitoringTier,
  carePlanCreationDataDirectives,
  patientId,
}: PrescriptionModalProps) => {
  const prescriptionInfo: PartialPrescriptionGenerationData = {
    first_name: patientInfo.givenName,
    last_name: patientInfo.familyName,
    birthDate: patientInfo.birthDate,
    weight: patientInfo.weight ? patientInfo.weight.toString() : '',
    telemonitoringTier: telemonitoringTier,
    duration: duration,
    periodStart: carePlanCreationDataDirectives.periodStart,
  };
  const [addPrescriptionData, dispatch] = useReducer(AddPrescriptionReducer, {
    step: 'GenerateOrUploadPrescription',
    prescriptionGenerationData: prescriptionInfo,
  });

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xl">
      <Container
        dispatch={dispatch}
        addPrescriptionData={addPrescriptionData}
        onPrescriptionReady={onPrescriptionReady}
        onCloseModal={onClose}
        patientId={patientId}
      />
    </Dialog>
  );
};
