import React, { useLayoutEffect, useMemo } from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { DailyGraph } from '@/components/data-representations/DailyGraph/DailyGraph';
import { DayPicker } from '@/components/period-picker/DayPicker';
import useDebounce from '@/hooks/useDebounce';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useQueryString } from '@/hooks/useQueryString';
import { useStyles } from '@/hooks/useStyles';
import { useWindowSize } from '@/hooks/useWindowSize';
import { Queries } from '@/queries/Queries';
import { dateYMD_ISO } from '@/utils/date';

export const PatientData: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = usePatientIdFromURL();
  const { date } = useQueryString();
  const dateDebounced = useDebounce<string>(date, 100);
  const [width, setWidth] = React.useState(0);
  const windowSize = useWindowSize();
  const divRef = React.useRef<HTMLDivElement>(null);
  const styles = useStyles(makeStyles);
  const patientId = usePatientIdFromURL() ?? '';
  const dataviz = Queries.diabetes.useDataviz({
    patientId,
    from: moment(dateDebounced).startOf('day').format(),
    to: moment(dateDebounced).endOf('day').format(),
  });

  useLayoutEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.offsetWidth);
    }
  }, [windowSize.width]);

  const handleDateChange = (newDate: moment.Moment) =>
    navigate(`/patients/${id}/data?date=${dateYMD_ISO(newDate)}`);

  const dailyData = useMemo(() => {
    if (dataviz.isSuccess) {
      const data = dataviz.data[0];
      return {
        ...data,
        glycemiaTypes: {
          glycemiaSensor: data.glycemia
            .filter(glycemia => glycemia.type === 'interstitial')
            .sort((a, b) => moment(a.date).diff(moment(b.date))), //generateGlyc(),
          glycemiaReader: data.glycemia.filter(
            glycemia => glycemia.type === 'capillary',
          ),
          glycemiaManual: [],
        },
      };
    } else {
      return undefined;
    }
  }, [dataviz.data, dataviz.isSuccess, date]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h4">{t('patientNavigation.dailyView')}</Typography>
        <DayPicker setDate={handleDateChange} date={moment(date)} />
      </div>
      <Card elevation={0}>
        <CardBody htmlRef={divRef}>
          {dailyData ? (
            <DailyGraph {...{ data: dailyData }} width={width * 0.95} />
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: ${theme.spacing(8)};
  `,
  header: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
});
