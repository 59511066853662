import React, { useState } from 'react';

import { css, cx } from '@emotion/css';
import { Button, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { useStyles } from '@/hooks/useStyles';
import {
  CurrentCarePlan,
  TelemonitoringInfo,
} from '@/pages/patient-settings/CurrentCarePlan';
import { Queries } from '@/queries/Queries';

import { CarePlanRenewal } from './CarePlanRenewal';

export type TelemonitoringCardProps = {
  patientId: string;
  className?: string;
};

export const TelemonitoringCard: React.FC<TelemonitoringCardProps> = ({
  patientId,
  className,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const { data: carePlanData } = Queries.practitioner.useCarePlans(patientId);
  const { data: practitionerData } = Queries.practitioner.usePractitioner({});

  const [isTelemonitoringDrawerOpen, setIsTelemonitoringDrawerOpen] =
    useState(false);

  if (!carePlanData || !practitionerData) {
    return null;
  }

  const isNurse = practitionerData.qualification === 'nurse';

  const canRenewCarePlan = carePlanData.can_renew_care_plan;

  // No renewal suggestion means we can still renew, but we don't have a reason
  const buttonLabel =
    carePlanData.renewal_suggestion === 'expiring'
      ? t('diabetesForms.renew') // For now, expiring also displays 'renew' but in the future more cases will come
      : t('diabetesForms.renew'); // Default to 'renew' when no suggestion

  return (
    <Card className={cx(styles.container, className)} elevation={0}>
      <TextCardTitle
        id="tls-title"
        title={t('pages.patientSettings.telemonitoring.title')}
      />
      <CardBody>
        {carePlanData.current_care_plan ? (
          <CurrentCarePlan
            carePlan={carePlanData.current_care_plan}
            patientId={patientId}
          />
        ) : (
          <TelemonitoringInfo patientId={patientId} />
        )}
        {!isNurse ? (
          <div className={styles.alightRight}>
            {canRenewCarePlan ? (
              <>
                <Button
                  startIcon={<Icons.filePlus />}
                  variant="contained"
                  onClick={() => setIsTelemonitoringDrawerOpen(true)}
                >
                  {buttonLabel}
                </Button>
                {isTelemonitoringDrawerOpen && (
                  <CarePlanRenewal
                    patientId={patientId}
                    onClose={() => setIsTelemonitoringDrawerOpen(false)}
                  />
                )}
              </>
            ) : (
              <Button
                variant="outlined"
                disabled
                startIcon={<Icons.filePlus />}
              >
                {t('diabetesForms.renewed')}
              </Button>
            )}
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
  `,
  alightRight: css`
    align-self: flex-end;
  `,
});
