import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { Settings } from 'luxon';
import moment from 'moment-timezone';
import { initReactI18next } from 'react-i18next';

import en from '@/locales/en/tl.json';
import fr from '@/locales/fr/tl.json';

export const Languages = {
  en: { tl: en },
  fr: { tl: fr },
} satisfies { [key: string]: { tl: typeof fr } };

export const SupportedLanguages = Object.keys(Languages);

export const i18nInstance = i18n.createInstance();

i18nInstance
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    resources: Languages,
    debug: import.meta.env.DEV,
    fallbackLng: 'fr',
    supportedLngs: SupportedLanguages,
    nonExplicitSupportedLngs: true,
    lowerCaseLng: true,
    ns: 'tl',
    defaultNS: 'tl',

    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['querystring', 'localStorage', 'sessionStorage', 'navigator'],
      caches: ['localStorage'],
    },
  })
  .then(() => {
    moment.locale(i18n.language);
    Settings.defaultLocale = i18n.language;
    document.documentElement.lang = i18n.language;
  });

i18n.on('languageChanged', lng => {
  moment.locale(lng);
  Settings.defaultLocale = lng;
  document.documentElement.lang = lng;
});

i18n.on('missingKey', (lngs, _, key) => {
  console.error(`Missing translation key: ${key} for ${lngs}`);
});
