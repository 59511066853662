import { css } from '@emotion/css';

import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { PatientsTagsList } from '@/pages/patients/PatientsTagsList';
import { Queries } from '@/queries/Queries';

export const ExpiredTag = () => {
  const patientId = usePatientIdFromURL();
  const { data: carePlanData } = Queries.practitioner.useCarePlans(
    patientId ?? '',
  );
  const styles = useStyles(makeStyles);

  if (!carePlanData) {
    return null;
  }

  return (
    <div className={styles.container}>
      {patientId && carePlanData ? (
        <PatientsTagsList
          telemonitoringTags={carePlanData.telemonitoring_tags}
        />
      ) : null}
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    align-self: center;
  `,
  tag: css`
    align-self: center;
  `,
});
