import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';

import { PatientList } from '@/models/PatientModel';
import { userFormattedName } from '@/utils/user';

import { PatientForDoctor } from '../PatientDoctorTable';

const columnHelper = createColumnHelper<PatientForDoctor | PatientList>();

export const familyNameColumn = columnHelper.accessor('familyName', {
  cell: info => (
    <Typography sx={{ fontWeight: 'bold' }}>
      {userFormattedName(info.getValue(), info.row.original.givenName)}
    </Typography>
  ),
  header: () => <div></div>,
  size: 48,
  maxSize: 48,
});
