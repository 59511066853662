import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useStyles';

type DateInCellProps = {
  date: string;
};

const options: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
};

export const PrettyDate = ({ date }: DateInCellProps) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  if (date.includes('avg')) {
    return (
      <div className={styles.container}>
        <Typo className={styles.date} type="paragraph">
          {t('bgmLogBook.average')}
        </Typo>
      </div>
    );
  }
  const prettyDate = new Date(date).toLocaleDateString(undefined, options);
  const [day, completDate] = prettyDate.split(' ');

  return (
    <div className={styles.container}>
      <Typo className={styles.date} type="paragraph">
        {day}
      </Typo>
      <Typo className={styles.date} type="paragraph">
        {completDate}
      </Typo>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  date: css`
    color: ${theme.palette.violet.main};
  `,
  container: css`
    margin: auto;
    display: flex;
    flex-direction: column;
  `,
});
