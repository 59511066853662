import React, { useRef } from 'react';

import { Drawer, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Practitioner } from '@/models/PractitionerModel';
import { ValidateOrRejectIdentity } from '@/models/identity/FrenchIdentity';
import { ValidatePatientCard } from '@/pages/patients/ValidatePatientCard';
import { Queries } from '@/queries/Queries';

type ValidateIdentitiesProps = {
  practitioner: Practitioner;
};

export const ValidateIdentities: React.FC<ValidateIdentitiesProps> = ({
  practitioner,
}) => {
  const { t } = useTranslation();
  const identities = Queries.practitioner.useIdentitiesToValidate(
    {},
    {
      enabled: practitioner.qualification === 'doctor',
    },
  );
  const patientsSubmitted = useRef<Set<string>>(new Set());

  const validateOrRejectQuery =
    Queries.practitioner.useValidateOrRejectIdentity({
      onMutate: ({ patientId }) => patientsSubmitted.current.add(patientId),
      onSettled: (_, __, { patientId }) =>
        patientsSubmitted.current.delete(patientId),
    });

  if (practitioner.qualification === 'nurse') {
    return null;
  }

  const validateOrReject =
    (patientId: string) => (action: ValidateOrRejectIdentity) =>
      validateOrRejectQuery.mutate({ patientId, action }, {});

  if (!identities.data || identities.data.length === 0) {
    return null;
  }

  return (
    <Drawer open={identities.data.length > 0} anchor="right">
      <div style={{ padding: '20px' }}>
        <Typography variant="h4">{t('identityValidation.title')}</Typography>
        <Typography>{t('identityValidation.subtitle')}</Typography>
      </div>
      <div style={{ padding: '20px' }}>
        {identities.data.map(identity => (
          <ValidatePatientCard
            key={identity.patientId}
            identity={identity}
            submitting={patientsSubmitted.current.has(identity.patientId)}
            validateOrReject={validateOrReject(identity.patientId)}
          />
        ))}
      </div>
    </Drawer>
  );
};
