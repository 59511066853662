import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Col } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { TelemonitoringCard } from '@/pages/patient-settings/TelemonitoringCard';
import { AlertSettings } from '@/pages/patient-settings/alert-settings/AlertSettings';
import { ThresholdSettings } from '@/pages/patient-settings/threshold-settings/ThresholdSettings';
import { Queries } from '@/queries/Queries';

export default function PatientSettings() {
  const styles = useStyles(makeStyles);
  const patientId = usePatientIdFromURL();
  const patientQuery = Queries.practitioner.usePatient(patientId ?? '');
  const { data: carePlans } = Queries.practitioner.useCarePlans(
    patientId ?? '',
    {
      enabled: !!patientId,
    },
  );

  if (!patientQuery.data) {
    return (
      <Col align="center" justify="center">
        <Loader size="L" />
      </Col>
    );
  }

  const patient = patientQuery.data;
  return (
    <div className={styles.container}>
      <TelemonitoringCard patientId={patient.id} />
      {carePlans?.current_care_plan ? (
        <div className={styles.content}>
          <ThresholdSettings />
          <AlertSettings />
        </div>
      ) : null}
    </div>
  );
}

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${theme.spacing(12)};
  `,
  content: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(8)};
  `,
});
