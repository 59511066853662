import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { PageContainer } from '@/pages/PageContainer';
import { AboutSteto } from '@/pages/account/AboutSteto';
import { Security } from '@/pages/account/Security';
import { UserHelp } from '@/pages/account/UserHelp';

export const PatientAccountPage: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <PageContainer
      title={t('pages.account.title')}
      titleClassName={styles.title}
      className={styles.content}
    >
      <Security />
      <UserHelp />
      <AboutSteto />
    </PageContainer>
  );
};

const makeStyles = (theme: Theme) => ({
  title: css`
    margin: 0;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(12)};
  `,
});
