import React, { ChangeEvent, useRef } from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { ScrollableDiv } from '@/uiKit/ScrollableDiv';
import { TextAreaNestableType } from '@/uiKit/input/textArea/textArea';

export const TextAreaNestable = (
  props: TextAreaNestableType & React.HTMLProps<HTMLDivElement>,
) => {
  const styles = useStyles(makeStyle);
  const defaultValue = useRef(props.defaultValue);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onInput) {
      props.onInput(event);
    }
  };

  return (
    <div className={cx(styles.container, props.className)}>
      {props.header && <div>{props.header}</div>}
      <ScrollableDiv className={styles.scroll}>
        <div
          placeholder={props.placeholder}
          id="text-area-nestable"
          className={styles.textArea}
          {...props}
          contentEditable
          suppressContentEditableWarning={true}
          onInput={handleInput}
        >
          {defaultValue.current}
        </div>
      </ScrollableDiv>
    </div>
  );
};

TextAreaNestable.displayName = 'TextAreaNestable';

const makeStyle = (theme: Theme) => ({
  container: css`
    border-width: ${theme.spacing(0.5)};
    border-color: ${theme.palette.grey[600]};
    border-radius: ${theme.spacing(8)};
    border-style: solid;
    width: 430px;
    color: ${theme.palette.grey[900]};
    position: relative;
  `,
  scroll: css`
    height: 15vh;
  `,
  textArea: css`
    width: 100%;
    display: inline-block;
    white-space: pre-wrap;
    -moz-appearance: textfield-multiline;
    -webkit-appearance: textarea;
    flex-shrink: 0;
    padding: ${theme.spacing(8)};
    :empty:before {
      content: attr(placeholder);
      color: ${theme.palette.text.secondary};
    }
    outline: none;
  `,
});
