import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Keycloak from 'keycloak-js';

import { LoadingScreen } from '@/components/loading/LoadingScreen';
import { apiDataSource } from '@/io/datasource/ApiDatasource';
import { Login } from '@/pages/login/Login';

const keycloakInstance = new Keycloak({
  url: import.meta.env.VITE_APP_AUTH_URL || 'https://auth.steto.care/auth/',
  realm: 'steto',
  clientId: import.meta.env.VITE_APP_AUTH_CLIENT_ID || 'steto-frontend',
});

export const KeycloakContext = createContext({
  initialized: false,
  ...keycloakInstance,
} as Keycloak & { initialized: boolean });

export const useKeycloak = () => {
  const keycloak = useContext(KeycloakContext);

  if (!keycloak) {
    throw new Error(
      'useKeycloak hook must be used inside KeycloakAuth context',
    );
  }

  return keycloak;
};

export type KeycloakAuthProps = PropsWithChildren;
export const KeycloakAuth: React.FC<KeycloakAuthProps> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const context = useMemo(() => {
    return {
      initialized,
      ...keycloakInstance,
    } as Keycloak & { initialized: boolean };
  }, [initialized]);

  useEffect(() => {
    keycloakInstance.onAuthSuccess = () => {
      keycloakInstance.token &&
        apiDataSource.createInstance(keycloakInstance.token);
    };
    keycloakInstance.onTokenExpired = () => {
      keycloakInstance.updateToken(5);
    };
    const init = async () => {
      await keycloakInstance.init({
        onLoad: 'check-sso',
      });

      setInitialized(true);
    };

    init();
  }, []);

  return (
    <KeycloakContext.Provider value={context}>
      <KeycloakManager>{children}</KeycloakManager>
    </KeycloakContext.Provider>
  );
};

type KeycloakManagerProps = PropsWithChildren;

const KeycloakManager: React.FC<KeycloakManagerProps> = ({ children }) => {
  const keycloak = useKeycloak();

  if (!keycloak.initialized) {
    return <LoadingScreen />;
  }

  if (!keycloak.authenticated) {
    return <Login />;
  }

  return <>{children}</>;
};
