import { array } from 'typescript-json-decoder';

import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import {
  CarePlanDirectives,
  carePlanDirectivesDecoder,
} from '@/models/CarePlanDirectivesModel';
import { CarePlans, carePlansDecoder } from '@/models/CarePlanModel';
import { CareTeam, careTeamDecoder } from '@/models/CareTeamModel';
import { Contact, contactDecoder } from '@/models/ContactModel';
import { CreatePatientModel } from '@/models/CreatePatientModel';
import { EditTherapeuticObjectivesData } from '@/models/EditTherapeuticObjectivesModel';
import {
  GlycemiaDisplayMode,
  glycemiaDisplayModeDecoder,
} from '@/models/GlycemiaDisplayModeModel';
import { MessageCount, messageCountDecoder } from '@/models/MessageCountModel';
import {
  Patient,
  PatientID,
  PatientList,
  patientDecoder,
  patientIDDecoder,
  patientListDecoder,
} from '@/models/PatientModel';
import { RenewCarePlanData } from '@/models/RenewCarePlanModel';
import { ValidateOrRejectIdentity } from '@/models/identity/FrenchIdentity';
import {
  IdentityValidation,
  identityValidationDecoder,
} from '@/models/identity/IdentityValidationModel';
import { ContactData } from '@/pages/patient-medical-file/contact-patient/ContactPatient.schema';
import { Result } from '@/utils/Result';
import { forcedArray } from '@/utils/decoderUtils';

/**
 * For health pro only
 */
export class ManagePatientRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getPatientList = (): Promise<Result<PatientList[], ApiErrorResponse>> => {
    return this.datasource.get(
      `practitioners/patients/`,
      forcedArray(patientListDecoder),
    );
  };

  getPatient = (id: string): Promise<Result<Patient, ApiErrorResponse>> => {
    return this.datasource.get(`practitioners/patients/${id}/`, patientDecoder);
  };

  createPatient = (
    data: CreatePatientModel,
  ): Promise<Result<PatientID, ApiErrorResponse>> => {
    return this.datasource.post(
      `practitioners/patients/create/`,
      patientIDDecoder,
      {
        ...data,
        patient: {
          ...data.patient,
          birthDate: data.patient.birthDate?.toISODate(),
        },
      },
    );
  };

  renewCarePlan = (
    patientId: string,
    data: RenewCarePlanData,
  ): Promise<Result<PatientID, ApiErrorResponse>> => {
    return this.datasource.post(
      `practitioners/renew-care-plan/${patientId}/`,
      patientIDDecoder,
      data,
    );
  };

  editTherapeuticObjectives = (
    carePlanId: string,
    data: EditTherapeuticObjectivesData,
  ): Promise<Result<PatientID, ApiErrorResponse>> => {
    return this.datasource.patch(
      `practitioners/edit-care-plan-description/${carePlanId}/`,
      patientIDDecoder,
      data,
    );
  };

  getPatientMessageCount = (
    id: string,
  ): Promise<Result<MessageCount, ApiErrorResponse>> => {
    return this.datasource.get(
      `practitioners/patients/${id}/message-count/`,
      messageCountDecoder,
    );
  };

  getCareTeam = (
    patientId: string,
  ): Promise<Result<CareTeam, ApiErrorResponse>> => {
    return this.datasource.get(
      `practitioners/patients/${patientId}/care-team/`,
      careTeamDecoder,
    );
  };

  getCarePlans = (
    patientId: string,
  ): Promise<Result<CarePlans, ApiErrorResponse>> => {
    return this.datasource.get(
      `practitioners/patients/${patientId}/care-plans/`,
      carePlansDecoder,
    );
  };

  getCarePlanDirectives = (
    patientId?: string,
  ): Promise<Result<CarePlanDirectives, ApiErrorResponse>> => {
    return this.datasource.get(
      patientId
        ? `practitioners/care-plan-directives/${patientId}/`
        : 'practitioners/care-plan-directives/',
      carePlanDirectivesDecoder,
    );
  };

  getListContacts = (
    patientId: string,
  ): Promise<Result<Contact[], ApiErrorResponse>> => {
    return this.datasource.get(
      `practitioners/patients/${patientId}/contacts/`,
      forcedArray(contactDecoder),
    );
  };

  getIdentitiesToValidate = (): Promise<
    Result<IdentityValidation[], ApiErrorResponse>
  > => {
    return this.datasource.get(
      `practitioners/identities-to-validate/`,
      array(identityValidationDecoder),
    );
  };

  getGlycemiaDisplayMode = (
    patientId: string,
  ): Promise<Result<GlycemiaDisplayMode, ApiErrorResponse>> => {
    return this.datasource.get(
      `practitioners/patients/${patientId}/glycemia-display-mode/`,
      glycemiaDisplayModeDecoder,
    );
  };

  validateOrRejectIdentity = (
    patientId: string,
    action: ValidateOrRejectIdentity,
  ): Promise<Result<PatientID, ApiErrorResponse>> => {
    return this.datasource.post(
      `practitioners/patients/${patientId}/validate-identity/${action}/`,
      patientIDDecoder,
    );
  };

  createPatientContacts = (
    patientId: string,
    data: ContactData,
  ): Promise<Result<Contact, ApiErrorResponse>> => {
    return this.datasource.post(`/contacts/${patientId}/`, contactDecoder, {
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      phones: [{ number: data.phone, description: 'mobile' }],
      comment: data.comment,
      is_emergency_contact: data.isEmergencyContact,
      relationship_with_patient: data.relationshipWithPatient,
    });
  };

  updatePatientContacts = (
    patientId: string,
    contactId: string,
    data: Partial<ContactData>,
  ): Promise<Result<Contact, ApiErrorResponse>> => {
    return this.datasource.patch(
      `/contacts/${patientId}/${contactId}/`,
      contactDecoder,
      {
        first_name: data.firstname,
        last_name: data.lastname,
        email: data.email,
        phones: [{ number: data.phone, description: 'mobile' }],
        comment: data.comment,
        is_emergency_contact: data.isEmergencyContact,
        relationship_with_patient: data.relationshipWithPatient,
      },
    );
  };

  deletePatientContacts = (
    patientId: string,
    contactId: string,
  ): Promise<Result<undefined, ApiErrorResponse>> => {
    return this.datasource.delete(
      `/contacts/${patientId}/${contactId}/`,
      () => undefined,
    );
  };
}
