import { ChangeEvent } from 'react';

import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddMessageInfoData } from '@/components/chat/AddMessage.schema.ts';
import { RecipientToggle } from '@/components/chat/footer/RecipientToggle';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { TextAreaNestable } from '@/uiKit/input/textArea/TextAreaNestable';

type TextBoxProps = {
  setValue: UseFormSetValue<AddMessageInfoData>;
  formValueGetter: (key: string) => string;
};
export const TextBox = ({ setValue, formValueGetter }: TextBoxProps) => {
  const { t } = useTranslation();
  const patientId = usePatientIdFromURL() ?? '';
  const visibility = formValueGetter('visibility');

  let placeholder;
  if (visibility === 'all') {
    placeholder = t('messaging.toAllPlaceholder');
  } else {
    placeholder = t('messaging.toPractitionerPlaceholder');
  }

  const onUpdateRecipient = (recipient: string) => {
    if (recipient === t('messaging.everybody')) {
      setValue?.('visibility', 'all');
      setValue?.('category', 'notification');
    } else {
      setValue?.('visibility', 'practitioners');
      setValue?.('category', 'alert');
    }
  };

  const InputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValue?.('contentText', e.currentTarget.innerText, {
      shouldValidate: true,
    });

    if (patientId) {
      sessionStorage.setItem(
        `${patientId}Draft`,
        e.currentTarget.innerText ? e.currentTarget.innerText : '',
      );
    }
  };
  const defaultValue = sessionStorage.getItem(`${patientId}Draft`);

  return (
    <TextAreaNestable
      placeholder={placeholder}
      header={<RecipientToggle onUpdate={onUpdateRecipient} />}
      defaultValue={defaultValue ? defaultValue : ''}
      onInput={InputHandler}
    />
  );
};
