import { css } from '@emotion/css';
import { InputLabel, Stack, Theme, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';

import { ChipSelect } from '../atoms/ChipSelect';

type ChipChoice = {
  displayName: string;
  value: string;
};

type ChipSelectorProps = {
  fieldName: string;
  choices: ChipChoice[];
  label?: string;
};

export const ChipSelector = ({
  fieldName,
  choices,
  label,
}: ChipSelectorProps) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const selectedChip = watch(fieldName);

  const handleChipClick = (chipValue: string) => {
    setValue(fieldName, chipValue);
    clearErrors(fieldName);
  };

  const error = errors[fieldName];
  const errorMessage = typeof error?.message === 'string' ? error.message : '';

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ required: t('form.required') }}
      render={({}) => (
        <>
          <InputLabel>
            <Typography variant="subtitle">{label}</Typography>
          </InputLabel>
          <Stack direction="row" spacing={20} className={styles.inputPadding}>
            {choices.map((choice: ChipChoice) => (
              <ChipSelect
                clickable={true}
                selected={selectedChip === choice.value}
                key={choice.value}
                label={choice.displayName}
                onClick={() => handleChipClick(choice.value)}
              />
            ))}
          </Stack>
          {error ? <Typography color="error">{errorMessage}</Typography> : null}
        </>
      )}
    />
  );
};

const makeStyles = (theme: Theme) => ({
  inputPadding: css`
    padding-top: ${theme.spacing(12)};
    padding-bottom: ${theme.spacing(12)};
  `,
});
