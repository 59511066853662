import React from 'react';

import { css } from '@emotion/css';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AgpProfile } from '@/components/data-representations/AgpProfile';
import { Col } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { Typo } from '@/components/typography/Text';
import { useComponentSize } from '@/hooks/useComponentSize';
import { DateRange } from '@/hooks/useDateRangeFromURL';
import { useStyles } from '@/hooks/useStyles';
import { AGPStats } from '@/models/AGPStatsModel';
import { DiabetesParameters } from '@/models/DiabetesDataModel';
import { Queries } from '@/queries/Queries';

// // MOCK
// const mockAGP = {
//   0: { 5: 98.45, 25: 168.75, 50: 232, 75: 274.25, 95: 311 },
//   1: { 5: 97.3, 25: 160, 50: 222, 75: 261.75, 95: 316.09999999997 },
//   2: { 5: 90.9, 25: 138.5, 50: 223, 75: 248, 95: 279.599999997 },
//   3: { 5: 97.6, 25: 150, 50: 223, 75: 240, 95: 272 },
//   4: { 5: 117.5500000001, 25: 178, 50: 209, 75: 231, 95: 258.79999 },
//   5: { 5: 113, 25: 168, 50: 195, 75: 221, 95: 260 },
//   6: { 5: 112, 25: 165, 50: 196, 75: 225, 95: 270 },
//   7: { 5: 97, 25: 185, 50: 198, 75: 235, 95: 275 },
//   8: { 5: 95, 25: 165, 50: 202, 75: 275, 95: 315 },
//   9: { 5: 98.45, 25: 168.75, 50: 232, 75: 274.25, 95: 311 },
//   10: { 5: 97.3, 25: 160, 50: 222, 75: 261.75, 95: 316.09999999997 },
//   11: { 5: 97.3, 25: 160, 50: 222, 75: 261.75, 95: 316.09999999997 },
//   12: { 5: 90.9, 25: 138.5, 50: 223, 75: 248, 95: 279.599999997 },
//   13: { 5: 97.6, 25: 150, 50: 223, 75: 240, 95: 272 },
//   14: { 5: 117.5500000001, 25: 178, 50: 209, 75: 231, 95: 258.79999 },
//   15: { 5: 113, 25: 168, 50: 195, 75: 221, 95: 260 },
//   16: { 5: 112, 25: 165, 50: 196, 75: 225, 95: 270 },
//   17: { 5: 97, 25: 185, 50: 198, 75: 235, 95: 275 },
//   18: { 5: 95, 25: 165, 50: 202, 75: 275, 95: 315 },
//   19: { 5: 98.45, 25: 168.75, 50: 232, 75: 274.25, 95: 311 },
//   20: { 5: 97.3, 25: 160, 50: 222, 75: 261.75, 95: 316.09999999997 },
//   21: { 5: 97, 25: 185, 50: 198, 75: 235, 95: 275 },
//   22: { 5: 90.9, 25: 138.5, 50: 223, 75: 248, 95: 279.599999997 },
//   23: { 5: 97.6, 25: 150, 50: 223, 75: 240, 95: 272 },
// };
// // ---

type AgpProfilesProps = {
  dateRange: DateRange;
};

export const AgpProfiles: React.FC<AgpProfilesProps> = ({ dateRange }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Col className={styles.body}>
      <Typo type="paragraphLarge">
        {t('pages.patientMonitoring.agp.title')}
      </Typo>
      <AgpProfilesData dateRange={dateRange} />
    </Col>
  );
};

type AgpProfilesDataProps = {
  dateRange: DateRange;
};

const AgpProfilesData: React.FC<AgpProfilesDataProps> = ({ dateRange }) => {
  const { t } = useTranslation();
  const glycemiaParameters = Queries.diabetes.useParameters(
    dateRange.patientId,
  ).data;
  const agp = Queries.diabetes.useAGPStats(dateRange);
  const styles = useStyles(makeStyles);

  if (!agp.isSuccess || !glycemiaParameters) {
    return (
      <Col className={styles.loader}>
        <Loader size="L" />
      </Col>
    );
  }

  const hasAGP = Object.entries(agp.data).length > 0 && glycemiaParameters;
  return hasAGP ? (
    <AgpProfilesGraph agp={agp.data} glycemiaParameters={glycemiaParameters} />
  ) : (
    <Typography variant="body">{t('charts.noData')}</Typography>
  );
};

type AgpProfilesGraphProps = {
  agp: AGPStats;
  glycemiaParameters: DiabetesParameters;
};

const AgpProfilesGraph: React.FC<AgpProfilesGraphProps> = ({
  agp,
  glycemiaParameters,
}) => {
  const styles = useStyles(makeStyles);
  const agpContainerRef = React.useRef<HTMLDivElement>(null);
  const componentSize = useComponentSize(agpContainerRef);

  return (
    <div ref={agpContainerRef} className={styles.graphDiv}>
      <AgpProfile
        agp={agp}
        thresholds={glycemiaParameters}
        width={componentSize.width ?? 0}
      />
    </div>
  );
};

const makeStyles = () => ({
  body: css`
    height: 250px;
  `,
  graphDiv: css`
    min-width: 0;
    display: flex;
    flex: 1 1 0;
    height: 24rem;
  `,
  loader: css`
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `,
});
