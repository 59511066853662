import { DecoderFunction, number } from 'typescript-json-decoder';

import { intersectionWithContext } from '@/utils/decoderUtils';

export type MessageCount = {
  count: number;
};

export const messageCountDecoder: DecoderFunction<MessageCount> =
  intersectionWithContext('MessageCount', {
    count: number,
  });
