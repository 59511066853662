import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { Dialog, Theme, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { FontSizes } from '@/assets/fonts';
import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { CardTitle } from '@/components/card/CardTitle';
import { DailyGraph } from '@/components/data-representations/DailyGraph/DailyGraph';
import { DataTooltip } from '@/components/data-representations/DailyGraph/DataTooltip';
import { Tooltip } from '@/components/floating/Tooltip';
import { Col } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { SideSheetHeader } from '@/components/sidesheet/SideSheetHeader';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useQueryString } from '@/hooks/useQueryString';
import { useStyles } from '@/hooks/useStyles';
import { useWindowSize } from '@/hooks/useWindowSize';
import { Dataviz } from '@/models/Dataviz';
import { ReportCategory } from '@/models/DiabetesDataModel';
import { Queries } from '@/queries/Queries';

export const reportCategories = {
  severe_hypoglycemia: 'alertConfig.severeHypoglycemia',
  perceived_hypoglycemia: 'alertConfig.perceivedHypoglycemia',
  perceived_hyperglycemia: 'alertConfig.perceivedHyperglycemia',
  tech_alert: 'alertConfig.technicalAlert',
  other: 'alertConfig.unspecified',
} satisfies Record<ReportCategory, string>;

export const GlycemiaProfiles: React.FC = () => {
  const { from, to } = useQueryString();
  const { t } = useTranslation();
  const dataviz = Queries.diabetes.useInfiniteDataviz({
    patientId: usePatientIdFromURL() ?? '',
    from: from,
    to: moment(to).isSame(moment(), 'day')
      ? to
      : moment(to).subtract(1, 'day').format(),
  });

  const [previousScroll, setPreviousScroll] = useState(0);

  const styles = useStyles(makeStyles);

  useEffect(() => {
    dataviz.refetch();
    setPreviousScroll(0);
  }, [from, to]);

  useEffect(() => {
    const handleScroll = () => {
      if (previousScroll > 0 && window.scrollY < 1000) {
        setPreviousScroll(0);
      }

      if (
        window.innerHeight + window.scrollY >=
          (document.scrollingElement?.scrollHeight ?? 0) * 0.9 &&
        previousScroll < window.scrollY
      ) {
        setPreviousScroll(window.scrollY);
        dataviz.fetchNextPage();
      } else if (previousScroll === 0 && window.scrollY > 1000) {
        setPreviousScroll(window.scrollY);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [previousScroll]);

  return (
    <Col>
      {previousScroll > 0 ? (
        <div className={styles.backToTop}>
          <IconButton
            buttonType="primary"
            icon={Icons.chevronUp}
            placement={'left'}
            className={styles.moreContentButton}
            iconClassname={styles.moreContentIcon}
            onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
          />
        </div>
      ) : null}
      <Card elevation={0}>
        <CardTitle>
          <Typography variant="h6">
            {t('pages.patientMonitoring.glycemia.title')}
          </Typography>
        </CardTitle>
        <CardBody>
          <div className={styles.list}>
            {dataviz.hasPreviousPage ? (
              <IconButton
                buttonType="secondary"
                icon={Icons.chevronUp}
                placement="left"
                className={styles.newerDataButton}
                iconClassname={styles.moreContentIcon}
                onClick={() => dataviz.fetchPreviousPage()}
              >
                {t('pages.patientMonitoring.loadMoreRecent')}
              </IconButton>
            ) : null}
            {dataviz.data?.pages
              .flatMap(a => a)
              .map(data => (
                <GlycemiaProfileOverview key={data.date} data={data} />
              ))}
            {dataviz.hasNextPage ? (
              <div className={styles.loader}>
                <Loader size="M" />
              </div>
            ) : null}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

const GlycemiaProfileOverview = ({ data }: { data: Dataviz }) => {
  const styles = useStyles(makeStyles);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Card elevation={0}>
      <CardTitle className={styles.itemTitle}>
        <div className={styles.itemDate}>
          <Typography variant="body">
            {moment(data.date).format('dddd DD MMM YYYY').capitalize()}
          </Typography>
          {data.reports.length > 0 ? (
            <Tooltip
              placement="top"
              content={
                <DataTooltip
                  data={data.reports.map(report => ({
                    title: t(reportCategories[report.category ?? 'other']),
                    dataInputMethod: t('charts.manual'),
                    content: report.message ? `"${report.message}"` : '',
                    date: report.date,
                  }))}
                />
              }
            >
              <Icons.alertCircle />
            </Tooltip>
          ) : null}
        </div>

        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          classes={styles.floating}
          fullScreen
        >
          <Card className={styles.fullscreenCard} elevation={0}>
            <SideSheetHeader
              className={styles.fullscreenHeader}
              title={t('pages.patientMonitoring.glycemia.title')}
              exit={() => setIsOpen(false)}
            />
            <CardBody className={styles.fullscreenContainer}>
              <GlycemiaGraph data={data} size="full" />
            </CardBody>
          </Card>
        </Dialog>
        <Icons.fullscreen
          className={styles.fullScreenIcon}
          onClick={() => setIsOpen(true)}
        />
      </CardTitle>
      <CardBody className={styles.itemBody}>
        <GlycemiaGraph data={data} size="compact" />
      </CardBody>
    </Card>
  );
};

type GlycemiaGraphProps = {
  data: Dataviz;
  size: 'compact' | 'full';
};

const GlycemiaGraph: React.FC<GlycemiaGraphProps> = ({ data, size }) => {
  const [width, setWidth] = React.useState(0);
  const divRef = React.useRef<HTMLDivElement>(null);
  const styles = useStyles(makeStyles);
  const windowSize = useWindowSize();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.offsetWidth);
    }
  }, [windowSize.width]);

  const dailyData = useMemo(() => {
    return {
      ...data,
      glycemiaTypes: {
        glycemiaSensor: data.glycemia
          .filter(glycemia => glycemia.type === 'interstitial')
          .sort((a, b) => moment(a.date).diff(moment(b.date))), //generateGlyc(),
        glycemiaReader: data.glycemia.filter(
          glycemia => glycemia.type === 'capillary',
        ),
        glycemiaManual: [],
      },
    };
  }, [data]);

  const hasData = useMemo(() => {
    return (
      size === 'full' ||
      data.reports.length > 0 ||
      data.glycemia.length > 0 ||
      data.food.length > 0 ||
      data.activity.length > 0 ||
      data.insulin.length > 0
    );
  }, [size, data]);

  return (
    <div className={styles.graphContainer} ref={divRef}>
      {hasData ? (
        <DailyGraph data={dailyData} width={width} size={size} />
      ) : (
        <Typography variant="body">
          {t('pages.patientMonitoring.noData')}
        </Typography>
      )}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  list: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(4)};
  `,
  itemTitle: css`
    padding: ${theme.spacing(1)} ${theme.spacing(12)};
    justify-content: space-between;
  `,
  itemDate: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(4)};
  `,
  itemBody: css`
    padding: 0 ${theme.spacing(12)} ${theme.spacing(2)} ${theme.spacing(12)};
  `,
  graphContainer: css`
    max-width: 100%;
  `,
  loader: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  newerDataButton: css`
    width: fit-content;
    color: ${theme.palette.text.primary};
  `,
  moreContentButton: css`
    width: fit-content;
    color: ${theme.palette.common.white};
  `,
  moreContentIcon: css`
    width: ${FontSizes.bodyLarge};
    height: ${FontSizes.bodyLarge};
  `,
  backToTop: css`
    position: fixed;
    bottom: ${theme.spacing(12)};
    align-self: flex-end;
    z-index: ${theme.zIndex.fab};
    stroke: ${theme.palette.grey[900]};
  `,
  fullScreenIcon: css`
    width: ${FontSizes.body};
    height: ${FontSizes.body};
    cursor: pointer;
  `,
  fullscreenCard: css`
    width: 100%;
    height: 100%;
  `,
  fullscreenHeader: css`
    padding: ${theme.spacing(12)};
  `,
  fullscreenContainer: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  `,
  floating: {
    root: css`
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    container: css`
      width: 80%;
      height: 90%;
    `,
  },
});
