import { Card } from '@/components/card/Card';

import { BGMContainerBody } from './BGMContainerBody';
import { BGMContainerHeader } from './BGMContainerHeader';

export const BGMContainer = () => {
  return (
    <Card elevation={0}>
      <BGMContainerHeader />
      <BGMContainerBody />
    </Card>
  );
};
