import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles.ts';
import { ActivityCommentType } from '@/models/BGMLogbook';

import { DetailComment } from './DetailComment';

export const ActivityComment = ({
  activityComment,
}: {
  activityComment: ActivityCommentType;
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const time = moment(activityComment.date).format('HH:mm');

  const text = `${time}, ${activityComment.duration}min, 
  ${t(`bgmLogBook.${activityComment.intensity}`)}`;

  return (
    <DetailComment
      className={styles.chip}
      icon={<Icons.activity className={styles.icon} />}
      commentText={text}
      tooltipText={t('bgmLogBook.activityTooltip')}
    />
  );
};

const makeStyles = (theme: Theme) => ({
  chip: css`
    background-color: ${theme.palette.bgm.orangeBackground};
  `,
  icon: css`
    height: ${theme.spacing(4)};
    color: ${theme.palette.bgm.activityIcon};
  `,
});
