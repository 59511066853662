import { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import { useSearchParams } from 'react-router-dom';

import { Row } from '@/components/layout/Flex';
import {
  PeriodPicker,
  SelectNextPeriod,
  SelectPreviousPeriod,
} from '@/components/period-picker/PeriodPicker/PeriodPicker';
import { Periodicities } from '@/components/period-picker/PeriodPicker/PeriodSelector';
import { useStyles } from '@/hooks/useStyles';
import { SelectedPeriod } from '@/pages/patient-monitoring/SelectedPeriod';

export type OnChangePeriodProps = {
  startDate: string;
  endDate: string;
};

export type PeriodicityType = {
  index: number;
  durationInDays: number;
};

export default function HeaderMonitoring() {
  const [periodicity, setPeriodicity] = useState<PeriodicityType>(
    periodicities[1],
  );
  const [nextPeriodDisable, setNextPeriodDisable] = useState(true);

  const styles = useStyles(makeStyles);

  const [searchParams, setSearchParams] = useSearchParams();

  const to = searchParams.get('to');
  const from = searchParams.get('from');
  const hasUrlParams = to !== null && from !== null;

  useEffect(() => {
    if (hasUrlParams) {
      const periodicity = getCurrentPeriodicity(from, to);
      setPeriodicity(periodicity);
    }
  }, [from, hasUrlParams, to]);

  useEffect(() => {
    if (!hasUrlParams) {
      const today = new Date();
      const baseDate = new Date();
      const downLimit = new Date(
        baseDate.setDate(baseDate.getDate() - periodicity['durationInDays']),
      );
      setSearchParams({
        from: urlDateFormat(downLimit.toString()),
        to: urlDateFormat(today.toString()),
      });
    }
  }, [from, hasUrlParams, periodicity, setSearchParams, to]);

  useEffect(() => {
    const today = new Date();
    if (hasUrlParams) {
      const endDate = new Date(to);

      const todayTs = today.setHours(0, 0, 0, 0);
      const endDateTs = endDate.setHours(0, 0, 0, 0);

      if (endDateTs >= todayTs) {
        setNextPeriodDisable(true);
      } else {
        setNextPeriodDisable(false);
      }
    }
  }, [searchParams, periodicity.durationInDays, hasUrlParams, to]);

  const onChangePeriodicity = (newPeriod: PeriodicityType) => {
    if (hasUrlParams) {
      setPeriodicity(newPeriod);
      const endDate = new Date(to);
      const newStart = new Date(
        endDate.setDate(endDate.getDate() - newPeriod.durationInDays),
      );
      setSearchParams({ from: urlDateFormat(newStart.toString()), to: to });
    }
  };

  const goToPreviousPeriodHandler = () => {
    if (hasUrlParams) {
      const previousStart = new Date(from);
      const previousEnd = new Date(to);
      const newStart = new Date(
        previousStart.setDate(
          previousStart.getDate() - periodicity.durationInDays,
        ),
      );
      const newEnd = new Date(
        previousEnd.setDate(previousEnd.getDate() - periodicity.durationInDays),
      );

      setSearchParams({
        from: urlDateFormat(newStart.toString()),
        to: urlDateFormat(newEnd.toString()),
      });
    }
  };

  const goToNextPeriodHandler = () => {
    if (hasUrlParams) {
      const previousStart = new Date(from);
      const previousEnd = new Date(to);
      let newStart;
      let newEnd = new Date(
        previousEnd.setDate(previousEnd.getDate() + periodicity.durationInDays),
      );
      const today = new Date();
      if (newEnd > today) {
        newEnd = new Date(today);
        newStart = new Date(
          today.setDate(today.getDate() - periodicity.durationInDays),
        );
      } else {
        newStart = new Date(
          previousStart.setDate(
            previousStart.getDate() + periodicity.durationInDays,
          ),
        );
      }

      setSearchParams({
        from: urlDateFormat(newStart.toString()),
        to: urlDateFormat(newEnd.toString()),
      });
    }
  };

  return (
    <Row className={styles.container}>
      <Row className={styles.picker}>
        <Row>
          <SelectPreviousPeriod
            onClick={goToPreviousPeriodHandler}
          ></SelectPreviousPeriod>
          {from && to && <SelectedPeriod from={from} to={to}></SelectedPeriod>}
          <SelectNextPeriod
            onClick={goToNextPeriodHandler}
            disabled={nextPeriodDisable}
          ></SelectNextPeriod>
        </Row>

        <PeriodPicker
          key={periodicity.index}
          periodicities={periodicities}
          defaultPeriodicity={periodicity}
          onChange={onChangePeriodicity}
        />
      </Row>
    </Row>
  );
}

const periodicities: Periodicities = [
  {
    durationInDays: 7,
    label: 'periodicity.oneWeek',
    index: 0,
  },
  {
    durationInDays: 14,
    label: 'periodicity.twoWeeks',
    index: 1,
  },
  {
    durationInDays: 30,
    label: 'periodicity.oneMonth',
    index: 2,
  },
  {
    durationInDays: 90,
    label: 'periodicity.threeMonths',
    index: 3,
  },
  {
    durationInDays: 180,
    label: 'periodicity.sixMonths',
    index: 4,
  },
  {
    durationInDays: 0,
    label: 'periodicity.customize',
    index: 5,
  },
];

const urlDateFormat = (date: string) => moment(date).format('YYYY-MM-DD');

const getCurrentPeriodicity = (from: string, to: string): PeriodicityType => {
  const dateFrom = new Date(from);
  const dateTo = new Date(to);
  const difference = dateTo.getTime() - dateFrom.getTime();
  const days = Math.ceil(difference / (1000 * 3600 * 24));
  return periodicities.find(e => e.durationInDays == days) || periodicities[1];
};

const makeStyles = (theme: Theme) => ({
  container: css`
    width: 100%;
    gap: ${theme.spacing(4)};
  `,
  picker: css`
    flex-grow: 1;
    align-items: stretch;
    gap: ${theme.spacing(4)};
    flex-wrap: wrap;
  `,
});
