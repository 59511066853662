import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import {
  DeviceType,
  MedicalDevice as MedicalDeviceModel,
} from '@/models/MedicalDeviceModel';

export type MedicalDeviceProps = {
  device: MedicalDeviceModel;
};

const medicalDeviceMapping = {
  reader: 'devices.reader',
  sensor: 'devices.sensor',
  pump: 'devices.pump',
  'remote monitoring': 'devices.remoteMonitoring',
  manual: 'devices.manual',
};

export const MedicalDevice: React.FC<MedicalDeviceProps> = ({ device }) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  const dateFromNow = (date: string, withoutSuffix?: boolean) =>
    moment(date).fromNow(withoutSuffix);
  const deviceLastSync = () =>
    `${t('pages.patientMedicalFile.lastSync')} ${dateFromNow(device.lastSync)}`;

  return (
    <div className={styles.device}>
      <div className={styles.deviceType}>
        <DeviceTypeLogo type={device.deviceType} />
        <Typography variant="body">
          {t(medicalDeviceMapping[device.deviceType])}
        </Typography>
      </div>
      <Typography variant="body" className={styles.deviceName}>
        {device.name.capitalize()}
      </Typography>
      <Typography variant="body" className={styles.lastSync}>
        {deviceLastSync()}
      </Typography>
    </div>
  );
};

const DeviceTypeLogo: React.FC<{ type: DeviceType }> = ({ type }) => {
  const styles = useStyles(makeStyles);
  switch (type) {
    case 'manual':
      return null;
    case 'pump':
      return <Icons.pump className={styles.deviceIcons} />;
    case 'reader':
      return <Icons.reader className={styles.deviceIcons} />;
    case 'remote monitoring':
      return (
        <Icons.logoRoundFull
          className={cx(styles.deviceIcons, styles.stetoIcon)}
        />
      );
    case 'sensor':
      return <Icons.sensor className={styles.deviceIcons} />;
  }
};

const makeStyles = (theme: Theme) => ({
  device: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(8)};
    justify-content: space-between;
    align-items: center;
  `,
  deviceType: css`
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.palette.text.primary};
  `,
  deviceName: css`
    flex: 1 1;
    text-align: center;
  `,
  lastSync: css`
    flex: 2 1;
    text-align: end;
    color: ${theme.palette.text.secondary};
  `,
  deviceIcons: css`
    margin: ${theme.spacing(0, 2)};
    width: ${theme.spacing(12)};
    height: ${theme.spacing(12)};
    color: ${theme.palette.text.primary};
  `,
  stetoIcon: css`
    color: ${theme.palette.environment};
  `,
});
