import { Reducer } from 'react';

import { DateTime } from 'luxon';

import { TelemonitoringTier } from '@/models/CarePlanModel';

export type PrescriptionGenerationData = {
  first_name: string;
  last_name: string;
  birthDate: DateTime;
  weight: string;
  telemonitoringTier: TelemonitoringTier;
  duration: string;
  periodStart: DateTime;
};

export type PartialPrescriptionGenerationData = Omit<
  PrescriptionGenerationData,
  'weight' | 'birthDate'
> & {
  weight?: string;
  birthDate?: DateTime;
};

export type AddPrescriptionData =
  | {
      step: 'GenerateOrUploadPrescription';
      prescriptionGenerationData: PartialPrescriptionGenerationData;
      prescription?: File | Blob;
    }
  | {
      step: 'GetPrescriptionData';
      prescriptionGenerationData: PartialPrescriptionGenerationData;
    }
  | {
      step: 'PrescriptionSignature';
      prescriptionGenerationData: PrescriptionGenerationData;
      prescription: Blob;
    };

export type AddPrescriptionActions =
  | {
      type: 'BACK_TO_GENERATE_OR_UPLOAD';
      prescriptionGenerationData: PartialPrescriptionGenerationData;
    }
  | {
      type: 'TO_GET_PRESCRIPTION_DATA';
      prescriptionGenerationData: PartialPrescriptionGenerationData;
    }
  | {
      type: 'TO_PRESCRIPTION_SIGNATURE';
      prescriptionGenerationData: PrescriptionGenerationData;
      prescription: Blob;
    }
  | {
      type: 'BACK_TO_PRESCRIPTION_DATA';
      prescriptionGenerationData: PrescriptionGenerationData;
    };

export const AddPrescriptionReducer: Reducer<
  AddPrescriptionData,
  AddPrescriptionActions
> = (prevState, action) => {
  switch (action.type) {
    case 'BACK_TO_GENERATE_OR_UPLOAD':
      return {
        ...prevState,
        step: 'GenerateOrUploadPrescription',
        prescriptionGenerationData: action.prescriptionGenerationData,
      };
    case 'TO_GET_PRESCRIPTION_DATA':
      return {
        ...prevState,
        step: 'GetPrescriptionData',
        prescriptionGenerationData: action.prescriptionGenerationData,
      };
    case 'TO_PRESCRIPTION_SIGNATURE':
      return {
        ...prevState,
        step: 'PrescriptionSignature',
        prescriptionGenerationData: action.prescriptionGenerationData,
        prescription: action.prescription,
      };
    case 'BACK_TO_PRESCRIPTION_DATA':
      return {
        ...prevState,
        step: 'GetPrescriptionData',
        prescriptionGenerationData: action.prescriptionGenerationData,
      };
    default:
      return prevState;
  }
};
