import React from 'react';

import { css, keyframes } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FontSizes } from '@/assets/fonts';
import { useStyles } from '@/hooks/useStyles';

export type LoadingScreenProps = {
  // Empty
};

export const LoadingScreen: React.FC<LoadingScreenProps> = ({}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Typography variant="body" className={styles.text}>
        {t('button.pending')}
      </Typography>
      <div className={styles.lineProgressBar}></div>
    </div>
  );
};

const growAnimation = keyframes`
  50% {
    width: 100%;
  }
  100% {
    width: 0;
    right: 0;
    left: unset;
  }
`;

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: ${theme.palette.primary.main};
  `,
  text: css`
    color: ${theme.palette.grey[100]};
    font-size: ${FontSizes.bodyLarge};
    line-height: ${FontSizes.h5};
  `,
  lineProgressBar: css`
    width: ${theme.spacing(100)};
    height: ${theme.spacing(1)};
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background: ${theme.palette.primary.light};
      animation: ${growAnimation} 1s ease-in-out infinite;
    }
  `,
});
