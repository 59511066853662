import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import { Tag } from '@/uiKit/tag/Tag';

type MessageCellType = {
  practitioner: number;
};

export const MessageCellDoctor = ({ practitioner }: MessageCellType) => {
  const styles = useStyles(makeStyles);

  if (practitioner <= 0) {
    return null;
  }

  return (
    <div className={styles.messageTagIcon}>
      <Tag
        icon={<Icons.letter width="1em" height="1em" />}
        label={practitioner.toString()}
        color="blue"
      />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  messageTagIcon: css`
    display: flex;
    padding: ${theme.spacing(0, 1)};
  `,
});
