import { ThemeOptions, createTheme } from '@mui/material';

import '@/theme/BaseTheme';
import {
  baseComponentsOverrides,
  baseTypographyOptions,
} from '@/theme/BaseTheme';
import { Colors } from '@/theme/Colors';

const ENV = import.meta.env.MODE;

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    text: {
      title: Colors.primary['900'],
      primary: Colors.neutral['900'],
      secondary: Colors.neutral['500'],
      disabled: Colors.neutral['400'],
    },
    primary: {
      background: Colors.primary['200'],
      light: Colors.primary['500'],
      main: Colors.primary['800'],
      dark: Colors.primary['900'],
      contrastText: Colors.neutral['100'],
    },
    lightPrimary: {
      background: Colors.primary['200'],
      light: Colors.primary['500'],
      main: Colors.primary['800'],
      dark: Colors.primary['900'],
      contrastText: Colors.neutral['100'],
    },
    secondary: {
      light: Colors.accent['300'],
      main: Colors.accent['500'],
      dark: Colors.accent['900'],
      contrastText: Colors.neutral['900'],
    },
    error: {
      contrastText: Colors.danger['300'],
      light: Colors.danger['500'],
      main: Colors.danger['700'],
      dark: Colors.danger['900'],
    },
    success: {
      contrastText: Colors.success['300'],
      light: Colors.success['500'],
      main: Colors.success['700'],
      dark: Colors.success['900'],
    },
    warning: {
      contrastText: Colors.warning['300'],
      light: Colors.warning['500'],
      main: Colors.warning['700'],
      dark: Colors.warning['900'],
    },
    info: {
      contrastText: Colors.info['300'],
      light: Colors.info['500'],
      main: Colors.info['700'],
      dark: Colors.info['900'],
    },
    grey: Colors.neutral,
    common: {
      black: Colors.black,
      white: Colors.white,
    },
    background: {
      default: Colors.neutral['50'],
      paper: Colors.white,
    },
    green: {
      contrastText: Colors.green['300'],
      light: Colors.green['500'],
      main: Colors.green['700'],
      dark: Colors.green['900'],
    },
    blue: {
      contrastText: Colors.info['300'],
      light: Colors.info['500'],
      main: Colors.info['700'],
      dark: Colors.info['900'],
    },
    orange: {
      contrastText: Colors.orange['300'],
      light: Colors.orange['500'],
      main: Colors.orange['600'],
      dark: Colors.orange['900'],
    },
    red: {
      contrastText: Colors.red['300'],
      light: Colors.red['500'],
      main: Colors.red['700'],
      dark: Colors.red['900'],
    },
    pink: {
      contrastText: Colors.pink['300'],
      light: Colors.pink['500'],
      main: Colors.pink['700'],
      dark: Colors.pink['900'],
    },
    violet: {
      contrastText: Colors.violet['300'],
      light: Colors.violet['500'],
      main: Colors.violet['700'],
      dark: Colors.violet['900'],
    },
    neutral: {
      contrastText: Colors.neutral['100'],
      light: Colors.neutral['400'],
      main: Colors.neutral['600'],
      dark: Colors.neutral['800'],
    },
    ivory: Colors.ivory,
    environment: Colors[ENV],
    glycemia: {
      severeHyperglycemia: {
        contrastText: Colors.glycemia.severeHyperglycemia['300'],
        light: Colors.glycemia.severeHyperglycemia['500'],
        main: Colors.glycemia.severeHyperglycemia['700'],
        dark: Colors.glycemia.severeHyperglycemia['900'],
      },
      hyperglycemia: {
        contrastText: Colors.glycemia.hyperglycemia['300'],
        light: Colors.glycemia.hyperglycemia['500'],
        main: Colors.glycemia.hyperglycemia['700'],
        dark: Colors.glycemia.hyperglycemia['900'],
      },
      normal: {
        contrastText: Colors.glycemia.normal['300'],
        light: Colors.glycemia.normal['500'],
        main: Colors.glycemia.normal['700'],
        dark: Colors.glycemia.normal['900'],
      },
      hypoglycemia: {
        contrastText: Colors.glycemia.hypoglycemia['300'],
        light: Colors.glycemia.hypoglycemia['500'],
        main: Colors.glycemia.hypoglycemia['700'],
        dark: Colors.glycemia.hypoglycemia['900'],
      },
      severeHypoglycemia: {
        contrastText: Colors.glycemia.severeHypoglycemia['300'],
        light: Colors.glycemia.severeHypoglycemia['500'],
        main: Colors.glycemia.severeHypoglycemia['700'],
        dark: Colors.glycemia.severeHypoglycemia['900'],
      },
    },
    insulin: {
      basal: {
        contrastText: Colors.insulin.basal['300'],
        light: Colors.insulin.basal['500'],
        main: Colors.insulin.basal['700'],
        dark: Colors.insulin.basal['900'],
      },
      bolus: {
        contrastText: Colors.insulin.bolus['300'],
        light: Colors.insulin.bolus['500'],
        main: Colors.insulin.bolus['700'],
        dark: Colors.insulin.bolus['900'],
      },
    },
    agp: {
      p90: Colors.agp.p90,
      p50: Colors.agp.p50,
      median: Colors.agp.median,
    },
    bgm: {
      header: Colors.bgm.header,
      clearColumn: Colors.bgm.clearColumn,
      darkColumn: Colors.bgm.darkColumn,
      orangeDarkBackground: Colors.bgm.orangeDarkBackground,
      redBackground: Colors.bgm.redBackground,
      orangeBackground: Colors.bgm.orangeBackground,
      greenBackground: Colors.bgm.greenBackground,
      purpleText: Colors.bgm.purpleText,
      activityIcon: Colors.bgm.activityIcon,
      purpleBackground: Colors.bgm.purpleBackground,
    },
  },
  /**
   * From Figma UIKIT
   * https://www.figma.com/design/ZVp70EdtHVxHi7PAQKj5zQ/Steto---UI-kit?node-id=1-3&t=1VF9HsEvNViaNYEt-0
   */
  spacing: (factor: number) => `${0.125 * factor}rem`,
  typography: baseTypographyOptions,
  components: baseComponentsOverrides,
};

export const lightTheme = createTheme(lightThemeOptions);
