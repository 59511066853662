import { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

export type ScrollBarProps = PropsWithChildren<{
  className?: string;
}>;

export const ScrollableDiv = ({ children, className }: ScrollBarProps) => {
  const styles = useStyles(makeStyles);
  return <div className={cx(styles.container, className)}>{children}</div>;
};

const makeStyles = (theme: Theme) => ({
  container: css`
    overscroll-behavior: contain;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    /* WebKit based browser style */
    &::-webkit-scrollbar {
      width: ${theme.spacing(4)};
    }

    &::-webkit-scrollbar-track {
      display: None;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.grey[600]};
      border-radius: ${theme.spacing(4)};
    }
  `,
});
