import { QueryClient } from '@tanstack/react-query';
import moment from 'moment';

import { DiabetesRepository } from '@/io/repository/DiabetesRepository';
import {
  BulkAddGlycemia,
  EditDiabetesParameters,
} from '@/models/DiabetesDataModel';
import { makeQueryKey, stripQueryResult } from '@/queries/Queries';
import { createInfiniteQuery } from '@/queries/utils/CreateInfiniteQuery';
import { createMutation } from '@/queries/utils/CreateMutation';
import { createQuery } from '@/queries/utils/CreateQuery';

type StatsRequest = { patientId: string; from: string; to: string };
type BGMParams = { patientId: string; from: string; to: string };

export class DiabetesQueries {
  constructor(
    private readonly diabetes: DiabetesRepository = new DiabetesRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/
  useInfiniteBGMLogbook = createInfiniteQuery(
    'infinite-bgm-logbook',
    ({ patientId, from, to }) => makeQueryKey(patientId, from, to),
    async ({ patientId, from, to }: BGMParams) =>
      stripQueryResult(await this.diabetes.getBGMLogbook(patientId, from, to)),
    (_, __, lastPageParam) => {
      const diff = moment(lastPageParam.to).diff(
        moment(lastPageParam.from),
        'days',
      );

      return {
        patientId: lastPageParam.patientId,
        from: moment(lastPageParam.from)
          .subtract(diff, 'days')
          .startOf('week')
          .toISOString(),
        to: moment(lastPageParam.from).subtract(1, 'days').toISOString(),
      };
    },
  );

  useDataviz = createQuery(
    'dataviz',
    ({ patientId, from, to }) => makeQueryKey(patientId, from, to),
    async ({ patientId, from, to }: StatsRequest) =>
      stripQueryResult(await this.diabetes.getDataViz(patientId, from, to)),
    {
      staleTime: 0,
    },
  );

  useInfiniteDataviz = createInfiniteQuery(
    'infinite-dataviz',
    ({ patientId, from, to }) => makeQueryKey(patientId, from, to),
    async ({ patientId, from, to }: StatsRequest) =>
      stripQueryResult(await this.diabetes.getDataViz(patientId, from, to)),
    (_, __, lastPageParam) => {
      let diff = Math.abs(
        moment(lastPageParam.from).diff(moment(lastPageParam.to), 'days'),
      );

      if (moment(lastPageParam.to).isBefore(moment(), 'day')) {
        diff += 1;
      }

      return {
        patientId: lastPageParam.patientId,
        from: moment(lastPageParam.from).subtract(diff, 'days').format(),
        to: moment(lastPageParam.from).subtract(1, 'days').format(),
      };
    },
    {
      getPreviousPageParam: (_, __, firstPageParam) => {
        if (moment(firstPageParam.to).isSameOrAfter(moment(), 'day')) {
          return undefined;
        }

        const diff =
          Math.abs(
            moment(firstPageParam.from).diff(moment(firstPageParam.to), 'days'),
          ) + 1;

        let newTo = moment(firstPageParam.to).add(diff, 'days').format();
        if (moment(newTo).isSameOrAfter(moment().subtract(1, 'day'), 'day')) {
          newTo = moment().format();
        }

        return {
          patientId: firstPageParam.patientId,
          from: moment(firstPageParam.to).add(1, 'days').format(),
          to: newTo,
        };
      },
      maxPages: 100,
    },
  );

  useParameters = createQuery(
    'parameters',
    patientId => [patientId],
    async (patientId: string) =>
      stripQueryResult(await this.diabetes.getParameters(patientId)),
  );

  useMedicalDevices = createQuery(
    'medical-devices',
    patientId => [patientId],
    async (patientId: string) =>
      stripQueryResult(await this.diabetes.getMedicalDevices(patientId)),
  );

  useTargetDurationStats = createQuery(
    'target-duration-stats',
    ({ patientId, from, to }) => makeQueryKey(patientId, from, to),
    async ({ patientId, from, to }: StatsRequest) =>
      stripQueryResult(
        await this.diabetes.getTargetDurationStats(patientId, from, to),
      ),
  );

  useGlobalStats = createQuery(
    'global-stats',
    ({ patientId, from, to }) => makeQueryKey(patientId, from, to),
    async ({ patientId, from, to }: StatsRequest) =>
      stripQueryResult(await this.diabetes.getGlobalStats(patientId, from, to)),
  );

  useAGPStats = createQuery(
    'agp-stats',
    ({ patientId, from, to }) => makeQueryKey(patientId, from, to),
    async ({ patientId, from, to }: StatsRequest) =>
      stripQueryResult(await this.diabetes.getAGPStats(patientId, from, to)),
  );

  /*******************************/
  /*********** MUTATIONS *********/
  /*******************************/

  useBulkAddGlycemia = createMutation(
    'bulk-add-glycemia',
    async (data: BulkAddGlycemia) =>
      stripQueryResult(await this.diabetes.bulkAddGlycemia(data)),
  );

  useEditParameters = createMutation(
    'edit-parameters',
    async (data: EditDiabetesParameters) =>
      stripQueryResult(await this.diabetes.editParameters(data)),
    {
      onSuccess: (parameters, { patientId }, { queryClient }) => {
        this.useParameters.manualUpdate(
          queryClient,
          parameters.id,
          () => parameters,
        );
        this.invalidateAllStats(queryClient, patientId);
      },
    },
  );

  useUploadFile = createMutation(
    'upload-file',
    async ({
      patientId,
      file,
      timezone,
    }: {
      patientId: string;
      file: File;
      timezone?: string;
    }) =>
      stripQueryResult(
        await this.diabetes.uploadFile(patientId, file, timezone),
      ),
  );

  /*******************************/
  /************ HELPERS **********/
  /*******************************/

  invalidateAllStats = (queryClient: QueryClient, patientId: string) => {
    this.useDataviz.invalidate(queryClient, { patientId });
    this.useInfiniteDataviz.invalidate(queryClient, { patientId });
    this.useTargetDurationStats.invalidate(queryClient, { patientId });
    this.useGlobalStats.invalidate(queryClient, { patientId });
    this.useAGPStats.invalidate(queryClient, { patientId });
  };
}
