import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { ShowApiError } from '@/components/error/ShowApiError';
import { Col } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { ActiveNotifications } from '@/components/notifications/ActiveNotifications';
import { useDateRangeFromURL } from '@/hooks/useDateRangeFromURL';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { useWindowSize } from '@/hooks/useWindowSize';
import { GlycemiaProfiles } from '@/pages/patient-monitoring/GlycemiaProfiles';
import { GlycemiaStats } from '@/pages/patient-monitoring/GlycemiaStats/GlycemiaStats';
import HeaderMonitoring from '@/pages/patient-monitoring/HeaderMonitoring';
import { InsulinStats } from '@/pages/patient-monitoring/InsulinStats/InsulinStats';
import { PatientSummaryCard } from '@/pages/patient-monitoring/PatientSummaryCard';
import { RPMInfo } from '@/pages/patient-monitoring/RPMInfo';
import { Queries } from '@/queries/Queries';

import { BGMContainer } from './BGMLogBook/BGMContainer';

export const PatientMonitoring: React.FC = () => {
  const patientId = usePatientIdFromURL();
  const dateRange = useDateRangeFromURL();
  const patientQuery = Queries.practitioner.usePatient(patientId ?? '');
  const styles = useStyles(makeStyles);
  const { screenSize } = useWindowSize();
  const { data: glycemiaDisplayData } =
    Queries.practitioner.useGlycemiaDisplayMode(patientId ?? '', {
      enabled: !!patientId,
    });

  switch (patientQuery.status) {
    case 'error':
      return (
        <ShowApiError errorMapping={{}} error={patientQuery.error.error} />
      );
    case 'pending':
      return (
        <Col className={styles.loading}>
          <Loader size="L" />
        </Col>
      );
    case 'success': {
      const patient = patientQuery.data;
      const glycemiaData = (
        <>
          {glycemiaDisplayData?.display_mode === 'logbook' ? (
            <BGMContainer />
          ) : (
            <GlycemiaProfiles />
          )}
        </>
      );
      switch (screenSize) {
        case 'SM':
        case 'MD':
        case 'LG':
          return (
            <div className={styles.container}>
              <div className={styles.content}>
                <HeaderMonitoring />
                <GlycemiaStats dateRange={dateRange} />
                <InsulinStats dateRange={dateRange} />
                <div className={styles.inlinedAside}>
                  <div className={styles.asideContent}>
                    <PatientSummaryCard patient={patient} />
                    <RPMInfo patientId={patient.id} />
                  </div>
                  <ActiveNotifications
                    patientId={patient.id}
                    className={styles.asideContent}
                  />
                </div>
                {glycemiaData}
              </div>
            </div>
          );
        case 'XL':
        case 'XXL':
          return (
            <div className={styles.container}>
              <div className={styles.content}>
                <HeaderMonitoring />
                <GlycemiaStats dateRange={dateRange} />
                <InsulinStats dateRange={dateRange} />
                {glycemiaData}
              </div>

              <aside className={styles.aside}>
                <PatientSummaryCard patient={patient} />
                <RPMInfo patientId={patient.id} />
                <ActiveNotifications patientId={patient.id} />
              </aside>
            </div>
          );
      }
    }
  }
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: ${theme.spacing(8)};
    margin-bottom: ${theme.spacing(12)};
  `,
  content: css`
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: ${theme.spacing(8)};
    min-width: 0;
  `,
  aside: css`
    display: flex;
    flex-direction: column;
    flex: 0 1 300px;
    gap: ${theme.spacing(8)};
    padding-top: ${theme.spacing(28)};
  `,
  inlinedAside: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(8)};
    flex-wrap: wrap;
  `,
  asideContent: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
    flex: 1 1 0;
    height: fit-content;
  `,
  loading: css`
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
});
