import { css, cx } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { TelemonitoringTags } from '@/models/CarePlanModel';
import { StyleType } from '@/uiKit/StyleTypes';
import { Tag } from '@/uiKit/tag/Tag';
import { TagList } from '@/uiKit/tag/TagList';

type TagColor = 'green' | 'neutral' | 'red';
type TagData = {
  color: TagColor;
  label: string;
};

const TelemonitoringTagsMap: Record<TelemonitoringTags, TagData> = {
  active: {
    color: 'green',
    label: 'pages.patients.active',
  },
  expiring_soon: {
    color: 'neutral',
    label: 'pages.patients.expiringSoon',
  },
  expired_recently: {
    color: 'red',
    label: 'pages.patients.expiredRecently',
  },
  expired: {
    color: 'red',
    label: 'pages.patients.expired',
  },
};

type PatientsTagsListProps = {
  telemonitoringTags: TelemonitoringTags[];
};

export const PatientsTagsList = ({
  className,
  telemonitoringTags,
}: PatientsTagsListProps & StyleType) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  if (!telemonitoringTags.length) {
    return null;
  }

  return (
    <TagList className={cx(styles.container, className)}>
      {telemonitoringTags.map(tag => {
        const tagData = TelemonitoringTagsMap[tag];
        return <Tag key={tag} color={tagData.color} label={t(tagData.label)} />;
      })}
    </TagList>
  );
};
const makeStyles = () => ({
  container: css`
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 24px; // MUI Chip height
    align-content: flex-start;
  `,
});
