import { css, cx } from '@emotion/css';
import { Button, Theme, Typography } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from '@/components/form/Form';
import { FileView } from '@/components/pdf/FileView';
import { useStyles } from '@/hooks/useStyles';
import { CarePlanData } from '@/models/CreatePatientModel';
import { Patient } from '@/models/PatientModel';
import { ChipSelector } from '@/uiKit/input/ControlledChipChoice';
import { DropDown } from '@/uiKit/input/ControlledDropDown';
import { ControlledFileUpload } from '@/uiKit/input/ControlledFileInput';
import { ControlledTextField } from '@/uiKit/input/ControlledTextField';
import { TelemonitoringCriteriaPick } from '@/uiKit/molecules/TelemonitoringCriteriaPick';
import { TelemonitoringTierChip } from '@/uiKit/molecules/TelemonitoringTierChip';

type TelemonitoringInformationProps = {
  onSubmit: (data: CarePlanData) => void;
  onBack: (data: CarePlanData) => void;
  carePlanFormData?: Partial<CarePlanData>;
  patientInfo: Pick<Patient, 'givenName' | 'familyName'>;
  patientId?: string;
};

export const TelemonitoringInformation = ({
  onSubmit,
  onBack,
  carePlanFormData,
  patientInfo,
  patientId = undefined,
}: TelemonitoringInformationProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const defaultValues = {
    diabetesType: carePlanFormData?.diabetesType || undefined,
    insulinType: carePlanFormData?.insulinType || undefined,
    telemonitoringCriteria:
      carePlanFormData?.telemonitoringCriteria || undefined,
    duration: carePlanFormData?.duration || '3',
    description: carePlanFormData?.description || undefined,
    descriptionAttachment: carePlanFormData?.descriptionAttachment || undefined,
  };

  const isMutating = useIsMutating();

  const context = useForm<CarePlanData>({
    defaultValues: defaultValues,
  });

  const descriptionAttachment = context.watch('descriptionAttachment');

  const handleSubmit = (data: CarePlanData) => {
    const { description, descriptionAttachment } = data;

    context.clearErrors();

    if (!description && !descriptionAttachment) {
      context.setError('description', {
        type: 'manual',
        message: t('form.atLeastOne'),
      });
      return;
    }

    onSubmit(data);
  };

  return (
    <div>
      <Form
        onSubmit={handleSubmit}
        id="TelemonitoringForm"
        formContext={context}
      >
        <div className={cx(styles.formRow, styles.marginTop)}>
          <ChipSelector
            label={t('diabetesForms.diabetesType')}
            fieldName="diabetesType"
            choices={[
              {
                displayName: t('diabetesForms.diabetesType1'),
                value: 'type1',
              },
              {
                displayName: t('diabetesForms.diabetesType2'),
                value: 'type2',
              },
            ]}
          />
        </div>
        <div className={styles.formRow}>
          <ChipSelector
            label={t('diabetesForms.insulinScheme')}
            fieldName="insulinType"
            choices={[
              {
                displayName: t('diabetesForms.insulinMono'),
                value: 'mono',
              },
              {
                displayName: t('diabetesForms.insulinComplex'),
                value: 'multi',
              },
            ]}
          />
        </div>
        <div className={styles.formRow}>
          <Typography variant="subtitle">
            {t('diabetesForms.criteria')}
          </Typography>
          <div className={styles.sideBySide}>
            <TelemonitoringCriteriaPick patientId={patientId} />
            <TelemonitoringTierChip patientId={patientId} />
          </div>
        </div>
        <div className={styles.formRow}>
          <Typography variant="subtitle">
            {t('diabetesForms.prescriptionDuration')}
          </Typography>
          <DropDown
            className={styles.padding}
            fieldName="duration"
            choices={[
              {
                displayName: `3 ${t('diabetesForms.months')}`,
                value: '3',
              },
              {
                displayName: `2 ${t('diabetesForms.months')}`,
                value: '2',
              },
              {
                displayName: `1 ${t('diabetesForms.months')}`,
                value: '1',
              },
            ]}
          />
        </div>
        <div className={styles.formRow}>
          <div className={styles.marginBottom}>
            <Typography variant="subtitle">
              {t('diabetesForms.goalsShort')}
            </Typography>
          </div>
          <div>
            {descriptionAttachment && (
              <div className={styles.smallPadding}>
                <FileView
                  file={descriptionAttachment}
                  onDelete={() =>
                    context.setValue('descriptionAttachment', undefined)
                  }
                />
              </div>
            )}
            <div className={styles.aside}>
              <ControlledTextField
                name="description"
                multiline
                required={false}
              />
              <ControlledFileUpload
                name="descriptionAttachment"
                fileType="description"
                defaultValue={carePlanFormData?.descriptionAttachment}
                patientInfo={patientInfo}
                displayFilename={false}
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            onClick={() => onBack(context.getValues())}
            variant="outlined"
          >
            {t('button.cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isMutating > 0}
          >
            {t('button.next')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  formRow: css`
    width: 100%;
  `,
  footer: css`
    display: flex;
    justify-content: right;
    padding-top: ${theme.spacing(10)};
    padding-bottom: ${theme.spacing(10)};
    gap: ${theme.spacing(20)};
    width: 100%;
  `,
  sideBySide: css`
    display: flex;
    gap: ${theme.spacing(10)};
    padding-top: ${theme.spacing(12)};
    padding-bottom: ${theme.spacing(12)};
  `,
  aside: css`
    justify-content: space-between;
    display: flex;
    width: 100%;
    gap: ${theme.spacing(12)};
  `,
  padding: css`
    padding-top: ${theme.spacing(12)};
    padding-bottom: ${theme.spacing(12)};
  `,
  smallPadding: css`
    padding-top: ${theme.spacing(0)};
    padding-bottom: ${theme.spacing(4)};
  `,
  marginBottom: css`
    margin-bottom: ${theme.spacing(12)};
  `,
  marginTop: css`
    margin-top: ${theme.spacing(20)};
  `,
});
