import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { InsulinStatItem } from '@/components/stat/InsulinStatItem';
import { useStyles } from '@/hooks/useStyles';
import { GlobalStats } from '@/models/GlobalStatsModel';

export type InsulinOverviewProps = {
  insulin: GlobalStats['insulin'];
  className?: string;
};

export const InsulinOverview: React.FC<InsulinOverviewProps> = ({
  insulin,
  className,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <div className={cx(styles.container, className)}>
      <InsulinStatItem
        className={styles.item}
        title={t('pages.patientMonitoring.insulin.averageNbInjectionsPerDay')}
        value={insulin.daily_mean_count_injections ?? 0}
        precision={1}
      />
      <InsulinStatItem
        className={styles.item}
        title={t('pages.patientMonitoring.insulin.averageInsulinPerDay')}
        value={
          Math.round(insulin.daily_mean_basal_insulin ?? 0) +
          Math.round(insulin.daily_mean_bolus_insulin ?? 0)
        }
        unit="U"
        precision={1}
      />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(16)};
    justify-content: space-around;
  `,
  item: css``,
});
