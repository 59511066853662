import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import moment from 'moment';

import { Icons } from '@/assets/icons';
import { Calendar } from '@/components/calendar/Calendar';
import { MenuTooltip } from '@/components/floating/MenuTooltip';
import { useStyles } from '@/hooks/useStyles';
import { isSameDate } from '@/utils/common';

const LITERAL_MOMENT_FORMAT = '[Le] DD MMMM YYYY';

type DayPickerProps = {
  date: moment.Moment;
  setDate: React.Dispatch<moment.Moment>;
};

export const DayPicker: React.FC<DayPickerProps> = ({ date, setDate }) => {
  const styles = useStyles(makeDayPickerStyles);

  const decrementPeriodicity = () => {
    const newDate = date?.subtract(1, 'days');
    setDate(moment(newDate));
  };

  const incrementPeriodicity = () => {
    if (dateIsToday) return;

    const newDate = date?.add(1, 'days');
    setDate(moment(newDate));
  };

  const dateIsToday = isSameDate(date, moment());

  return (
    <div className={styles.container}>
      <SelectPreviousRange onClick={decrementPeriodicity} />
      <MenuTooltip
        placement="bottom"
        wrapperClassName={styles.tooltipWrapper}
        closeOnInteract={false}
        content={
          <Calendar
            className={styles.calendar}
            date={date.toDate()}
            refDate={date.toDate()}
            onDateChange={(value: Date) => setDate(moment(value))}
            tileDisabled={({ date: day }) => day > new Date()}
            showNeighboringMonth={false}
            minDetail="year"
          />
        }
      >
        <button className={styles.date}>
          {date?.format(LITERAL_MOMENT_FORMAT)}
        </button>
      </MenuTooltip>

      <SelectNextRange disabled={dateIsToday} onClick={incrementPeriodicity} />
    </div>
  );
};

const makeDayPickerStyles = (theme: Theme) => ({
  container: css`
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
  `,
  tooltipWrapper: css`
    height: 100%;
    display: flex;
    justify-content: stretch;
  `,
  date: css`
    width: ${theme.spacing(96)};
    padding: ${theme.spacing(4)} ${theme.spacing(6)};
    margin: 0 ${theme.spacing(4)};
    text-align: center;
    background-color: ${theme.palette.common.white};
  `,

  calendar: css`
    background-color: ${theme.palette.common.white};
    padding: ${theme.spacing(4)};
    width: 100%;
    margin-top: ${theme.spacing(4)};
  `,
});

const SelectPreviousRange = ({
  onClick,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const styles = useStyles(makeSelectRangeStyles, false);
  return (
    <button className={styles.button} onClick={onClick}>
      <Icons.arrowLeft />
    </button>
  );
};

const SelectNextRange = ({
  onClick,
  disabled,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled: boolean;
}) => {
  const styles = useStyles(makeSelectRangeStyles, disabled);

  return (
    <button className={styles.button} disabled={disabled} onClick={onClick}>
      <Icons.arrowRight />
    </button>
  );
};

const makeSelectRangeStyles = (theme: Theme, disabled: boolean) => ({
  button: css`
    background-color: ${theme.palette.common.white};
    padding: ${theme.spacing(6)};
    color: ${disabled ? theme.palette.grey[300] : theme.palette.text.primary};
  `,
});
