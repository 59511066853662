import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles.ts';
import { FoodComment } from '@/models/BGMLogbook';

import { DetailComment } from './DetailComment';

const mealTranslations = {
  breakfast: 'bgmLogBook.breakfast',
  lunch: 'bgmLogBook.lunch',
  dinner: 'bgmLogBook.dinner',
  snack: 'bgmLogBook.snack',
  unknown: undefined,
} satisfies Record<FoodComment['meal'], string | undefined>;

const quantityTranslations = {
  light: 'bgmLogBook.light',
  medium: 'bgmLogBook.medium',
  big: 'bgmLogBook.big',
  unknown: undefined,
} satisfies Record<FoodComment['size'], string | undefined>;

export const MealComment = ({ mealComment }: { mealComment: FoodComment }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  if (!mealComment.carbs && mealComment.size === 'unknown') {
    // If there is no information about the meal, we don't show anything
    return null;
  }

  let text = '';
  const meal = mealTranslations[mealComment.meal];
  if (meal) {
    text = `${t(meal)}, `;
  }

  text +=
    mealComment.size !== 'unknown'
      ? t(quantityTranslations[mealComment.size])
      : `${mealComment.carbs}g`;

  if (mealComment.comments) {
    text += `, ${mealComment.comments}`;
  }

  return (
    <DetailComment
      className={styles.chip}
      icon={<Icons.meal className={styles.icon} />}
      commentText={text}
      tooltipText={t('bgmLogBook.mealTooltip')}
    />
  );
};

const makeStyles = (theme: Theme) => ({
  chip: css`
    background-color: ${theme.palette.bgm.greenBackground};
  `,
  icon: css`
    height: ${theme.spacing(8)};
    color: ${theme.palette.green.main};
  `,
});
