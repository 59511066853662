import React, { ReactElement } from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Col } from '@/components/layout/Flex';
import { SideSheetActionsProps } from '@/components/sidesheet/SideSheetActions';
import { SideSheetBodyProps } from '@/components/sidesheet/SideSheetBody';
import { SideSheetHeaderProps } from '@/components/sidesheet/SideSheetHeader';
import { useStyles } from '@/hooks/useStyles';

type SideSheetChild =
  | ReactElement<SideSheetHeaderProps>
  | ReactElement<SideSheetBodyProps>
  | ReactElement<SideSheetActionsProps>;

export type SidePeekProps = {
  className?: string;
  children: SideSheetChild | SideSheetChild[];
};

export const SidePeek: React.FC<SidePeekProps> = ({ children }) => {
  const styles = useStyles(makeStyles);

  return <Col className={styles.sidePeekContainer}>{children}</Col>;
};

const makeStyles = (theme: Theme) => ({
  sidePeekContainer: css`
    border-radius: ${theme.spacing(16)} 0px 0px ${theme.spacing(16)};
    background: ${theme.palette.common.white};
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    width: min(500px, 95vw);
    height: 100vh;
    position: fixed;
    right: 0;
    z-index: ${theme.zIndex.modal};
    overflow: hidden !important;
    justify-content: space-between;
  `,
});
