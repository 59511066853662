import { DecoderFunction, field, string } from 'typescript-json-decoder';

import { Profession, professionDecoder } from '@/models/ProfessionModel';
import { recordWithContext, stringUnion } from '@/utils/decoderUtils';

export type CareTeamParticipantRole = 'doctor' | 'nurse';

export type CareTeamParticipant = {
  id: string;
  role: CareTeamParticipantRole;
  givenName: string;
  familyName: string;
  qualification: Profession;
  userId: string;
};

export const careTeamParticipantDecoder: DecoderFunction<CareTeamParticipant> =
  recordWithContext('CareTeamParticipant', {
    id: string,
    role: stringUnion('doctor', 'nurse'),
    givenName: string,
    familyName: string,
    qualification: professionDecoder,
    userId: field('user_id', string),
  });
