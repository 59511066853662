import { DateField } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ControlledDateFieldProps = {
  name: string;
  label?: string;
  required?: boolean;
  rules?: any;
  variant?: 'standard' | 'outlined' | 'filled';
  maxDate?: any;
};

export const ControlledDateField = ({
  label,
  required = true,
  rules,
  name,
  variant,
  maxDate,
}: ControlledDateFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();

  const error = errors[name];
  const errorMessage = error ? error?.message : null;

  const errorMessageToDisplay =
    errorMessage && typeof errorMessage === 'string' ? errorMessage : '';

  const timeValidationRule = {
    validDate: (value: DateTime) => value.isValid || t('errors.dateFormat'),
  };
  const defaultRules = required
    ? { required: t('form.required'), validate: timeValidationRule }
    : timeValidationRule;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules ? rules : defaultRules}
      render={({ field }) => {
        const { value, ...rest } = field;
        return (
          <DateField
            {...rest}
            color={error ? 'error' : 'primary'}
            value={value || null}
            format="dd/MM/yyyy"
            variant={variant ? variant : 'standard'}
            label={label}
            fullWidth
            maxDate={maxDate}
            helperText={errorMessageToDisplay}
          />
        );
      }}
    />
  );
};
