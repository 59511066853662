import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ControlledTextFieldProps = {
  name: string;
  label?: string;
  required?: boolean;
  rules?: any;
  multiline?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  className?: string;
};

export const ControlledTextField = ({
  label,
  required = false,
  rules,
  name,
  multiline = false,
  variant,
  className,
}: ControlledTextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();
  const error = errors[name];
  const errorMessage = error ? error?.message : null;
  const errorMessageToDisplay =
    errorMessage && typeof errorMessage === 'string' ? errorMessage : ' ';
  const defaultRules = required ? { required: t('form.required') } : {};

  return (
    <Controller
      name={name}
      control={control}
      rules={rules ? rules : defaultRules}
      render={({ field }) => {
        const { value, ...rest } = field;
        return (
          <TextField
            error={!!error}
            value={value || ''}
            {...rest}
            variant={variant ? variant : 'outlined'}
            label={label}
            fullWidth
            multiline={multiline}
            rows={multiline ? 4 : 1}
            helperText={errorMessageToDisplay}
            className={className}
          />
        );
      }}
    />
  );
};
