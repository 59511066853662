import moment from 'moment';

import { BaseTooltip } from '@/components/floating/BaseTooltip';
import { BGMContentrationType } from '@/models/BGMLogbook';

export const Insulin = ({ data }: { data: BGMContentrationType[] }) => {
  const insulinFormatted = data.map(el => el.concentration).join('+');
  const timeFormatted = data
    .map(el => moment(el.date).format('HH:mm'))
    .join('+');
  return (
    <BaseTooltip
      type="hover"
      placement="top"
      content={timeFormatted}
      role="tooltip"
    >
      <span>{insulinFormatted}</span>
    </BaseTooltip>
  );
};
