import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextToast } from '@/components/toast/TextToast';
import { useStyles } from '@/hooks/useStyles';
import {
  DiabetesParameters,
  DiabetesParametersType,
} from '@/models/DiabetesDataModel';
import { ThresholdInput } from '@/pages/patient-settings/threshold-settings/ThresholdInput';
import { ThresholdStep } from '@/pages/patient-settings/threshold-settings/ThresholdStep';
import { Queries } from '@/queries/Queries';

export type ThresholdStepListProps = {
  diabetesParameters: DiabetesParameters;
  patientId: string;
};

/**
 * List of thresholds to be displayed in the ThresholdSettings component.
 */
const thresholds: ThresholdStep[] = [
  'thresholdHyperglycemiaSevere',
  'thresholdHyperglycemia',
  'thresholdNormal',
  'thresholdHypoglycemia',
  'thresholdHypoglycemiaSevere',
] as const;

/**
 * List of inputs linked to their respective thresholds.
 * They are offset for better UX
 */
const thresholdInputMapping = {
  thresholdHyperglycemiaSevere: 'thresholdHyperglycemiaSevere',
  thresholdHyperglycemia: 'thresholdHyperglycemia',
  thresholdNormal: 'thresholdHypoglycemia',
  thresholdHypoglycemia: 'thresholdHypoglycemiaSevere',
  thresholdHypoglycemiaSevere: undefined,
} satisfies { [key in ThresholdStep]: DiabetesParametersType | undefined };

export const ThresholdStepList: React.FC<ThresholdStepListProps> = ({
  diabetesParameters,
  patientId,
}) => {
  const styles = useStyles(makeStyles);
  const updateThreshold = Queries.diabetes.useEditParameters();
  const { t } = useTranslation();

  const submitMutate = (threshold: DiabetesParametersType, value: number) =>
    updateThreshold.mutate(
      {
        patientId: patientId,
        id: diabetesParameters.id,
        [threshold]: value,
      },
      {
        onSuccess: () =>
          TextToast.success(
            `${t(`thresholdSettings.${threshold}`)}`,
            `${t('thresholdSettings.thresholdUpdated')}: ${
              diabetesParameters[threshold]
            } => ${value}`,
          ),
      },
    );

  return (
    <ul className={styles.container}>
      {thresholds.map(threshold => {
        const inputThreshold = thresholdInputMapping[threshold];
        return (
          <ThresholdStep
            key={threshold}
            threshold={threshold}
            className={styles.step}
          >
            {inputThreshold ? (
              <div className={styles.inputContainer}>
                <ThresholdInput
                  diabetesParameters={diabetesParameters}
                  threshold={inputThreshold}
                  onSubmit={value => submitMutate(inputThreshold, value)}
                />
              </div>
            ) : null}
          </ThresholdStep>
        );
      })}
    </ul>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
  step: css`
    position: relative;
    display: flex;
    justify-content: center;
  `,
  inputContainer: css`
    position: absolute;
    height: 2.5rem;
    bottom: -1.25rem;
    background-color: white;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: ${theme.spacing(2)};
    padding: ${theme.spacing(0, 8)};
  `,
});
