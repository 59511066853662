import React, { useEffect } from 'react';

import { css } from '@emotion/css';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { Form } from '@/components/form/Form';
import { Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useStyles';
import {
  DiabetesTypeInfo,
  InsulinSchemeInfo,
} from '@/pages/patient-settings/CurrentCarePlan';
import { Queries } from '@/queries/Queries';

export type TelemonitoringFormProps = {
  patientId: string;
};

type FormType = {
  type: string;
  medicationSchema: string;
  telemonitoringTier: string;
};

export const DiabetesInfo: React.FC<TelemonitoringFormProps> = ({
  patientId,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: carePlans } = Queries.practitioner.useCarePlans(patientId);
  const currentCarePlan = carePlans?.current_care_plan;

  const fakeForm = useForm<FormType>();
  const { data: condition } = Queries.condition.useCondition(patientId);

  useEffect(() => {
    if (currentCarePlan && condition) {
      fakeForm.setValue('type', condition.type);
      fakeForm.setValue('medicationSchema', condition.medicationSchema);
      fakeForm.setValue(
        'telemonitoringTier',
        currentCarePlan.telemonitoringTier,
      );
    }
  }, [fakeForm, currentCarePlan, condition]);

  if (!condition) {
    return null;
  }

  return (
    <Card className={styles.container} elevation={0}>
      <TextCardTitle
        id="tls-title"
        title={t('pages.patientMedicalFile.diabetes.title')}
      />
      <CardBody className={styles.body}>
        <Form
          id="FAKE_DIABETES_FORM"
          formContext={fakeForm}
          onSubmit={() => {
            // Empty
          }}
        >
          <Row className={styles.row}>
            <DiabetesTypeInfo condition={condition} />
            <InsulinSchemeInfo condition={condition} />
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

const makeStyles = () => ({
  container: css``,
  body: css``,
  row: css`
    width: 100%;
  `,
});
