import { useEffect } from 'react';

import { css } from '@emotion/css';
import { useFormContext } from 'react-hook-form';

import { useStyles } from '@/hooks/useStyles';
import { DiabetesType } from '@/models/CarePlanModel';
import { MedicationSchema } from '@/models/ConditionModel';
import { Queries } from '@/queries/Queries';
import { TelemonitoringCriteriaTranslationMap } from '@/utils/telemonitoringCriteriaTranslationMap';

import { DropDown } from '../input/ControlledDropDown';

type TelemonitoringCriteriaPickProps = {
  patientId?: string;
};

export const TelemonitoringCriteriaPick = ({
  patientId,
}: TelemonitoringCriteriaPickProps) => {
  const { data } = Queries.practitioner.useCarePlanDirectives({ patientId });
  const styles = useStyles(makeStyles);
  const { telemonitoringCriteriaMap, telemonitoringTierMap } = data || {};

  const context = useFormContext();
  const insulinType: MedicationSchema | undefined =
    context.watch('insulinType');
  const diabetesType: DiabetesType | undefined = context.watch('diabetesType');

  useEffect(() => {
    context.setValue('telemonitoringCriteria', undefined);
  }, [insulinType, diabetesType]);

  const criteriaChoice =
    insulinType &&
    diabetesType &&
    telemonitoringCriteriaMap &&
    telemonitoringTierMap
      ? telemonitoringCriteriaMap[diabetesType][insulinType].map(criteria => ({
          value: criteria,
          translationKey: TelemonitoringCriteriaTranslationMap[criteria],
        }))
      : [];

  return (
    <DropDown
      className={styles.formRow}
      fieldName="telemonitoringCriteria"
      choices={criteriaChoice}
      disabled={!diabetesType || !insulinType}
    />
  );
};

const makeStyles = () => ({
  formRow: css`
    width: 100%;
  `,
});
