import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MenuTooltip } from '@/components/floating/MenuTooltip';
import { UserPicture } from '@/components/user-picture/UserPicture';
import { useStyles } from '@/hooks/useStyles';
import { useKeycloak } from '@/keycloak';
import { Queries } from '@/queries/Queries';

export type UserMenuProps = {
  // Empty
};

export const UserMenu: React.FC<UserMenuProps> = ({}) => {
  const styles = useStyles(makeStyles);
  const user = Queries.user.useMe({});
  const patient = Queries.patient.usePatient(
    {},
    {
      enabled: user.isSuccess && user.data.userType === 'patient',
    },
  );
  const practitioner = Queries.practitioner.usePractitioner(
    {},
    {
      enabled:
        user.status === 'success' && user.data.userType === 'practitioner',
    },
  );

  const data =
    patient.status === 'success'
      ? patient.data
      : practitioner.status === 'success'
        ? practitioner.data
        : undefined;

  switch (data) {
    case undefined:
      return (
        <div className={styles.picture}>
          <UserPicture />
        </div>
      );
    default:
      return (
        <MenuTooltip
          openOnHover
          placement="right-end"
          floaterClassName={styles.floater}
          content={
            <div className={styles.tooltip} data-testid="user-menu-tooltip">
              <UserActions />
            </div>
          }
        >
          <div className={styles.picture}>
            <UserPicture
              givenName={data.givenName}
              familyName={data.familyName}
            />
          </div>
        </MenuTooltip>
      );
  }
};

const UserActions: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const keycloak = useKeycloak();

  return (
    <>
      <Link id="account" to="/account" className={styles.action}>
        {t('pages.account.title')}
      </Link>
      <Typography
        variant="body"
        onClick={() => keycloak.logout({ redirectUri: window.origin })}
        className={styles.action}
      >
        {t('navbar.disconnect')}
      </Typography>
    </>
  );
};

const makeStyles = (theme: Theme) => ({
  tooltip: css``,
  picture: css`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
  `,
  action: css`
    display: flex;
    padding: ${theme.spacing(4)} ${theme.spacing(8)};
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.background.default};
    }
  `,
  floater: css`
    min-width: min(200px, calc(90vw - ${theme.spacing(30)}));
    max-width: calc(90vw - ${theme.spacing(30)});
  `,
});
