import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { Loader } from '@/components/loading/Loader';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { ThresholdStepList } from '@/pages/patient-settings/threshold-settings/ThresholdStepList';
import { Queries } from '@/queries/Queries';

export type ThresholdSettingsProps = {
  // Empty
};

export const ThresholdSettings: React.FC<ThresholdSettingsProps> = ({}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const patientId = usePatientIdFromURL() ?? '';
  const diabetesParametersQuery = Queries.diabetes.useParameters(patientId);

  return (
    <Card elevation={0} className={styles.container}>
      <TextCardTitle title={t('thresholdSettings.thresholds')} />
      <CardBody>
        {diabetesParametersQuery.isSuccess ? (
          <ThresholdStepList
            diabetesParameters={diabetesParametersQuery.data}
            patientId={patientId}
          />
        ) : (
          <Loader size="L" />
        )}
      </CardBody>
    </Card>
  );
};

const makeStyles = () => ({
  container: css`
    height: fit-content;
  `,
});
