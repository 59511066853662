import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import { Tag } from '@/uiKit/tag/Tag';

type MessageCellType = {
  patient: number;
  practitioner: number;
  count: number;
};

export const MessageCellNurse = ({
  patient,
  practitioner,
  count,
}: MessageCellType) => {
  const styles = useStyles(makeStyles);
  let messageCount = count.toString();

  if (count <= 0) {
    return null;
  }
  const tagContent =
    patient && !practitioner ? (
      <div className={styles.messageTagIcon}>
        <Icons.letter height="1em" width="1em" />
        <div>{messageCount}</div>
      </div>
    ) : (
      <div className={styles.messageTagIcon}>
        <Icons.letter height="1em" width="1em" />
        <Icons.stethoscope height="1em" width="1em" />
        <div>{messageCount}</div>
      </div>
    );

  return (
    <div className={styles.messageTagIcon}>
      <Tag color="blue" label={tagContent} />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  messageTagIcon: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
});
