import { MenuItem, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DropDownSelect } from '../atoms/DropDownSelect';

type DropDownChoice = {
  displayName?: string;
  translationKey?: string;
  submitValue?: string;
  value: string;
};

type DropDownProps = {
  fieldName: string;
  choices: DropDownChoice[];
  disabled?: boolean;
  className?: string;
};

export const DropDown = ({
  fieldName,
  choices,
  className,
  disabled = false,
}: DropDownProps) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[fieldName];
  const errorMessage = typeof error?.message === 'string' ? error.message : '';

  return (
    <div className={className}>
      <Controller
        name={fieldName}
        control={control}
        disabled={disabled}
        rules={{ required: t('form.required') }}
        render={({ field: { value, ref, ...rest } }) => (
          <DropDownSelect
            {...rest}
            value={value === undefined ? '' : value}
            fullWidth
            error={!!error}
          >
            {choices.map(choice => {
              return (
                <MenuItem key={choice.value} value={choice.value}>
                  {choice.displayName
                    ? choice.displayName
                    : t(choice.translationKey as string)}
                </MenuItem>
              );
            })}
          </DropDownSelect>
        )}
      />
      {error ? <Typography color="error">{errorMessage}</Typography> : null}
    </div>
  );
};
