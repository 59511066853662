import { useState } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { TelemonitoringDrawer } from '@/components/sidesheet/TelemonitoringDrawer';
import { CarePlanData } from '@/models/CreatePatientModel';
import { Queries } from '@/queries/Queries';

import { TelemonitoringInformation } from '../patients/telemonitoringForms/TelemonitoringInformation';
import { PrescriptionModal } from '../patients/telemonitoringForms/prescriptionGeneration/PrescriptionModal';

export type CarePlanRenewalProps = {
  patientId: string;
  onClose: () => void;
};

export const CarePlanRenewal: React.FC<CarePlanRenewalProps> = ({
  patientId,
  onClose,
}) => {
  const { t } = useTranslation();
  const { data: currentCarePlanData } =
    Queries.practitioner.useCarePlans(patientId);
  const renewMutation = Queries.practitioner.useRenewCarePlan();

  const navigate = useNavigate();

  const { data: carePlanDirectives } =
    Queries.practitioner.useCarePlanDirectives({ patientId });

  const { data: patientInfo } = Queries.practitioner.usePatient(patientId);

  const [isPrescriptionModalOpen, setIsPrescriptionModalOpen] = useState(false);

  const [carePlanCreationData, setCarePlanCreationData] =
    useState<CarePlanData>();

  if (!patientInfo || !currentCarePlanData || !carePlanDirectives) {
    return null;
  }

  return (
    <>
      <TelemonitoringDrawer
        isOpen={true}
        close={onClose}
        header={
          <Typography variant="h5">{t('diabetesForms.renew')}</Typography>
        }
      >
        <TelemonitoringInformation
          carePlanFormData={carePlanDirectives?.previousCarePlanData}
          patientId={patientId}
          onBack={onClose}
          onSubmit={data => {
            setCarePlanCreationData(data);
            setIsPrescriptionModalOpen(true);
          }}
          patientInfo={patientInfo}
        />
        {isPrescriptionModalOpen && carePlanCreationData ? (
          <PrescriptionModal
            patientInfo={patientInfo}
            onClose={() => {
              setIsPrescriptionModalOpen(false);
            }}
            patientId={patientId}
            carePlanCreationDataDirectives={
              carePlanDirectives.carePlanCreationDataDirectives
            }
            telemonitoringTier={
              carePlanDirectives.telemonitoringTierMap[
                carePlanCreationData.telemonitoringCriteria
              ]
            }
            onPrescriptionReady={({ prescription }) =>
              renewMutation.mutate(
                {
                  patientId,
                  data: {
                    ...carePlanCreationData,
                    prescription,
                  },
                },
                {
                  onSuccess: () => {
                    navigate(`/patients/${patientId}/`);
                  },
                },
              )
            }
            duration={carePlanCreationData.duration}
          />
        ) : null}
      </TelemonitoringDrawer>
    </>
  );
};
