import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { useStyles } from '@/hooks/useStyles';
import { MailPreferenceBody } from '@/pages/account/preferences/MailPreferenceBody';

export const MailPreference = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Card className={styles.container} elevation={0}>
      <TextCardTitle
        id="security-title"
        title={t('pages.account.preferences.title')}
      />
      <CardBody>
        <MailPreferenceBody />
      </CardBody>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    margin-top: ${theme.spacing(12)};
  `,
});
