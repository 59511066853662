import { useContext } from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { AttachmentContext } from '@/components/chat/footer/AttachmentContext';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { Status } from '@/utils/ReactQueryUtils';

type ActionType = {
  requestStatus: Status;
  formValueGetter: (key: string) => string;
};

export const Actions = ({ requestStatus, formValueGetter }: ActionType) => {
  const styles = useStyles(makeStyles);
  const attachmentContext = useContext(AttachmentContext);
  const patientId = usePatientIdFromURL() ?? '';

  const contentText = formValueGetter('contentText');
  const status = requestStatus;

  const sendDisable =
    attachmentContext.files?.length < 1 &&
    (!contentText || contentText.length < 1) &&
    sessionStorage.getItem(`${patientId}Draft`) === null;

  const sendButtonIconClass = sendDisable ? styles.disabledSendButtonIcon : '';
  const sendButtonClass = sendDisable
    ? styles.disabledSendButton
    : styles.button;

  return (
    <div className={styles.container}>
      <IconButton
        className={sendButtonClass}
        iconClassname={sendButtonIconClass}
        status={status}
        icon={Icons.sendMessage}
        placement="left"
        buttonType="primary"
        type="submit"
        disabled={sendDisable}
      ></IconButton>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    margin-left: auto;
    margin-top: auto;
    padding-left: ${theme.spacing(2)};
  `,
  button: css`
    border-radius: ${theme.spacing(12)};
    padding: ${theme.spacing(4)};
    aspect-ratio: 1;
  `,
  disabledSendButtonIcon: css`
    stroke: ${theme.palette.action.disabled};
  `,
  disabledSendButton: css`
    border-color: ${theme.palette.common.white};
    border-radius: ${theme.spacing(12)};
    padding: ${theme.spacing(4)};
  `,
});
