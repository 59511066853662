import { useContext } from 'react';

import { css } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { Theme } from '@mui/material';
import { useForm } from 'react-hook-form';

import {
  AddMessageInfoData,
  addMessageSchema,
} from '@/components/chat/AddMessage.schema';
import { Actions } from '@/components/chat/footer/Actions';
import { AttachmentContext } from '@/components/chat/footer/AttachmentContext';
import { FileListPreview } from '@/components/chat/footer/FileListPreview';
import { TextBox } from '@/components/chat/footer/TextBox';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';

export const FooterForm = () => {
  const styles = useStyles(makeStyles);
  const id = usePatientIdFromURL();
  const attachmentContext = useContext(AttachmentContext);

  const { handleSubmit, setValue, watch } = useForm<AddMessageInfoData>({
    defaultValues: {
      visibility: 'practitioners',
      category: 'alert',
      status: 'completed',
    },
    resolver: zodResolver(addMessageSchema),
  });

  const createMessage = Queries.chat.useCreateMessage();

  const onSubmit = (data: AddMessageInfoData) => {
    if (id) {
      data.contentAttachment = attachmentContext.files.map(f => ({
        file: f,
        name: f.name,
      }));
      createMessage.mutate({ patientId: id, ...data });
      const textArea = document.getElementById('text-area-nestable');
      setValue('contentText', '');
      if (textArea) textArea.innerHTML = '';
      attachmentContext.setFiles?.([]);
      sessionStorage.removeItem(`${id}Draft`);
    }
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      {attachmentContext.files.length > 0 && (
        <div className={styles.filePreviewContainer}>
          <FileListPreview />
        </div>
      )}
      <div className={styles.writeAction}>
        <TextBox setValue={setValue} formValueGetter={watch} />
        <Actions requestStatus={createMessage.status} formValueGetter={watch} />
      </div>
    </form>
  );
};
const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-flow: column;
    padding-bottom: ${theme.spacing(8)};
  `,
  filePreviewContainer: css`
    padding: ${theme.spacing(4)};
  `,
  writeAction: css`
    display: inline-flex;
    padding-left: ${theme.spacing(8)};
  `,
});
