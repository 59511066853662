import { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { Theme, useTheme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import { StyleType } from '@/uiKit/StyleTypes';

type ChipType = { background?: string; onClick?: () => void };

const Chip = ({
  background,
  onClick,
  children,
  className,
}: ChipType & PropsWithChildren & StyleType) => {
  const theme = useTheme();
  const backgroundColor = background
    ? background
    : theme.palette.background.default;

  const styles = useStyles(makeStyles, backgroundColor);
  return (
    <div
      onClick={onClick}
      className={cx(styles.container, className, backgroundColor)}
    >
      {children}
    </div>
  );
};

export default Chip;

const makeStyles = (theme: Theme, backgroundColor: string) => ({
  container: css`
    border-radius: ${theme.spacing(8)};
    background: ${backgroundColor};
    padding: ${theme.spacing(4)} ${theme.spacing(8)};
    gap: ${theme.spacing(8)};
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  `,
});
